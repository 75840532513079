import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/empty.png'
import utils from '@/js/utils'
import service from '@/js/service'
export const mixins = {
  components: {
    PullTo
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'new'
      }
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbSize: {
      type: Number,
      default: 380
    },
    columnNums: {
      type: Number,
      default: 0
    },
    fixHeight: {
      type: Number,
      default: 65
    },
    pageSize: {
      type: Number,
      default: 15
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    subtractHeight: {
      type: Number,
      default: 0
    },
    topPull: {
      type: Boolean,
      default: true
    },
    bottomPull: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const schema = {
      scrollTop: 0,
      // 瀑布流各列数据
      columns: [],
      horizWidth: 0,
      items: [],
      guest: {},
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      cursorSkipUp: 0,
      cursorSkipDown: 0,
      skip: 0,
      noUpPage: false,
      noDownPage: false
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop') {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    let horizThumbSize = 160
    if (this.state.platform.type !== 'mobile' && this.mod === 'index') {
      // 非移动端首页时，横向铺满
      horizThumbSize = Math.floor((utils.width() - (this.state.platform.type === 'desktop' ? 24 : 12)) / this.pageSize - 10)
    }
    const data = {
      container_width: container_width,
      container_height: container_height,
      width: container_width - 3,
      height: container_height - 95,
      // 水平局部时限制缩略图尺寸和水平宽度
      maxThumbWidth: horizThumbSize,
      maxThumbHeight: horizThumbSize,
      renew: false,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      showUp: false,
      emptyImage: emptyImage,
      schema: schema,
      dt: {
        'new': utils.deepClone(schema),
        'active': utils.deepClone(schema),
        'creator': utils.deepClone(schema),
        'follow': utils.deepClone(schema),
        'fans': utils.deepClone(schema),
        'guest': utils.deepClone(schema),
        'invite': utils.deepClone(schema),
        'discover': utils.deepClone(schema),
        'index': utils.deepClone(schema)
      },
      loading: {
        'new': false,
        'active': false,
        'creator': false,
        'follow': false,
        'fans': false,
        'guest': false,
        'invite': false,
        'discover': false,
        'index': false
      }
    }
    return data
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    reqId() {
      return this.state.reqId
    },
    itemSize() {
      if (this.columnNums) return Math.floor(this.width / this.columnNums) - 10
      return this.thumbSize
    },
    maxColumns() {
      return Math.max(Math.floor(this.width / (this.itemSize + 10)), 1)
    },
    columnWidth() {
      return Math.round(this.width / this.maxColumns)
    },
    cardWidth() {
      return this.columnWidth - 10
    },
    // 瀑布流高度
    flowHeight() {
      let height = 0
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const colHeight = its[its.length - 1]._height + its[its.length - 1]._top
        if (colHeight > height) height = colHeight
      }
      return height
    },
    headHeight() {
      return !this.isDesktop ? 40 : 0
    },
    listStyle() {
      if (this.subtractHeight) return { 'height': 'calc(100% - ' + this.subtractHeight + 'px)', 'margin-top': '5px' }
      return { 'height': 'calc(100% - ' + (5 + this.headHeight) + 'px)', 'margin-top': (5 + this.headHeight) + 'px' }
    },
    config() {
      return this.view.users
    },
    users() {
      return this.state.users
    },
    data() {
      return this.dt[this.mod]
    },
    itemNums() {
      return this.data.items.length
    },
    columns() {
      return this.data ? this.data.columns : []
    },
    followState() {
      return this.state.followState || {}
    },
    limit() {
      let limit = this.pageSize
      // 非首页及非移动端时分页数量X2
      if (!this.isMobile && this.mod !== 'index') limit = limit * 2
      return limit
    }
  },
  watch: {
    // 'view.users.show': {
    //   handler(show) {
    //     this.update()
    //     if (this.config.type === this.mod && show) {
    //       if (!this.itemNums) this.getData('down')
    //       this.deleteItems()
    //     }
    //   }
    // },
    'view.users.type': {
      handler() {
        if (this.config.type === this.mod && this.config.show) {
          if (!this.config.pop) this.emit('setLocationHash')
          this.deleteItems()
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.invite.show': {
      handler(show) {
        this.update()
        if (this.mod === 'invite' && show) this.updateData()
      }
    },
    'view.discover.show': {
      handler(show) {
        this.update()
        if (this.mod === 'discover' && show) this.updateData()
      }
    },
    'view.index.show': {
      handler() {
        if (this.view.index.show && this.mod === 'index') this.updateData()
      }
    },
    'view.relation.show': {
      handler(show) {
        this.update()
        if (this.view.relation.type === this.mod && show) this.updateData()
      }
    },
    'view.relation.type': {
      handler() {
        if (this.view.relation.type === this.mod && this.view.relation.show) this.updateData(true)
      }
    },
    'view.relation.userid': {
      handler() {
        if (this.mod === 'follow' || this.mod === 'fans' || this.mod === 'guest') this.init()
      }
    }
  },
  mounted() {
    this.calcSize()
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.config.type === this.mod && this.config.show && !this.itemNums) this.getData('down')
    if (this.view.index.show && this.mod === 'index') this.updateData()
  },
  methods: {
    // 转场
    goto(mod, dt) {
      if (mod === 'user') return
      this.emit('goto', [mod, dt])
    },
    // 逻辑删除
    deleteItems() {
      let deleteIds
      if (this.config.type === 'creator') deleteIds = this.state.deleteCreators
      if (this.config.type === 'active') deleteIds = this.state.deleteActiveUsers
      if (this.config.type === 'new') deleteIds = this.state.deleteUsers
      const ids = []
      for (var id in deleteIds) {
        ids.push(parseInt(id))
      }
      if (ids.length) {
        const items = []
        for (var i in this.data.items) {
          if (ids.indexOf(this.data.items[i].userid) < 0) items.push(this.data.items[i])
        }
        this.data.items = items
        if (this.config.type === 'creator') this.state.deleteCreators = {}
        if (this.config.type === 'active') this.state.deleteActiveUsers = {}
        if (this.config.type === 'new') this.state.deleteUsers = {}
        this.renderItems(this.data.items, true)
      }
    },
    // 移除
    removeItem(id) {
      for (var i in this.data.items) {
        if (this.data.items[i].userid === id) {
          this.data.items.splice(i, 1)
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 设置属性
    setItem(userid, key, value) {
      for (var i in this.data.items) {
        if (this.data.items[i].userid === userid) {
          this.data.items[i][key] = value
          return this.data.items[i]
        }
      }
    },
    scrollContainer() {
      const main = document.getElementById('users-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show && !this.view.relation.show && !this.view.invite.show && !this.view.discover.show && !this.view.index.show) return
      this.calcSize()
      // this.backTop()
      // this.data.scrollTop = 0
      this.renderItems(this.data.items, true)
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    showItem(item) {
      if (item._top <= this.data.scrollTop - this.height && item._top + item._height >= this.data.scrollTop - this.height) return true
      if (item._top >= this.data.scrollTop - this.height && item._top <= this.data.scrollTop + this.height + this.height) return true
      return false
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i]._height) + parseInt(arr[i]._top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      const arrIndex = []
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const height = its[its.length - 1]._height
        const top = its[its.length - 1]._top
        if (parseInt(height) + parseInt(top) === val) {
          arrIndex.push(i)
        }
      }
      return arrIndex
    },
    calcItemSize() {
      // 水平布局
      if (this.mod === 'index') {
        this.data.horizWidth = this.data.horizWidth + this.maxThumbWidth + 10
        return { width: this.maxThumbWidth, height: 0 }
      }
      return { width: this.itemSize, height: 0 }
    },
    renderItems(items, refresh = false) {
      if (refresh) {
        this.data.columns = []
        this.data.horizWidth = 0
      }
      if (!items.length) return
      // 初始化列、列的第一个元素
      let idx = 0
      if (this.columns.length < this.maxColumns || refresh) {
        for (let i = 0; i < this.maxColumns; i++) {
          if (!this.columns[i] && items[idx]) {
            const itemSize = this.calcItemSize()
            items[idx]._top = 0
            items[idx]._raw_width = itemSize.width
            items[idx]._raw_height = itemSize.height
            items[idx]._height = items[idx]._raw_height + this.fixHeight
            this.columns.push({ items: [items[idx]] })
            idx++
          }
        }
      }
      // 对剩余元素的判断，应该放到哪一列
      for (var index = idx; index < items.length; index++) {
        // 找到高度最小的一列，可能有多个
        const tmp = []
        for (let i = 0; i < this.columns.length; i++) {
          const its = this.columns[i].items
          tmp.push({
            _height: its[its.length - 1]._height,
            _top: its[its.length - 1]._top
          })
        }
        // 获取最矮的列高度
        const minHeight = this.getMinHeight(tmp)
        // 获取最矮列的索引
        let minHeightIdx = 0
        const arrIndex = this.getMinIndex(minHeight)
        // 出现高度一样的，取索引最小的
        if (arrIndex.length > 0) {
          minHeightIdx = Math.min.apply(null, arrIndex)
        }
        // 设置属性
        const itemSize = this.calcItemSize()
        items[index]._top = minHeight
        items[index]._raw_width = itemSize.width
        items[index]._raw_height = itemSize.height
        items[index]._height = items[index]._raw_height + this.fixHeight
        this.columns[minHeightIdx].items.push(items[index])
      }
    },
    calcSize() {
      if (this.state.platform.type === 'desktop') {
        this.container_width = 632
        this.container_height = 632
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
      }
      this.width = this.container_width - 3
      this.height = this.container_height - 95 - this.headHeight
      let horizThumbSize = 160
      if (this.state.platform.type !== 'mobile' && this.mod === 'index') {
        // 非移动端首页时，横向铺满
        horizThumbSize = Math.floor((utils.width() - (this.state.platform.type === 'desktop' ? 32 : 12)) / this.pageSize - 10)
      }
      this.maxThumbWidth = horizThumbSize
      this.maxThumbHeight = horizThumbSize
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.dt[this.mod] = utils.deepClone(this.schema)
    },
    update() {
      this.$forceUpdate()
    },
    updateData(updateHash) {
      if (updateHash && !this.config.pop) this.emit('setLocationHash')
      if (!this.itemNums) {
        this.init()
        this.getData('down')
      } else {
        this.update()
      }
    },
    getBgStye(item, size = 64) {
      const style = {
        width: this.maxThumbWidth + 'px',
        height: this.maxThumbHeight / 2 + 'px',
        'padding-left': (this.maxThumbWidth - size) / 2 + 'px',
        'padding-top': (this.maxThumbHeight - size - 20) / 2 + 'px'
      }
      const bg = this.getBackground(item)
      if (bg) {
        style['background-image'] = 'url(' + bg + ')'
        style['background-position'] = 'center center'
        style['background-size'] = '100% auto'
        style['background-repeat'] = 'no-repeat'
      }
      return style
    },
    getAvatar(item) {
      const ver = Math.round(new Date(item.avatar_at) / 1000)
      if (item.avatar) return service.getCosUrl(item.avatar, 'avatar', ver) || ''
    },
    getBackground(item) {
      const ver = Math.round(new Date(item.background_at) / 1000)
      if (item.background) return service.getCosUrl(item.background, 'background', ver) || ''
    },
    getSign(content, split) {
      if (!content) return ''
      return utils.fomateContent(content, split)
    },
    loadData(loaded, direction) {
      if (this.config.show || this.view.relation.show || this.view.invite.show || this.view.discover.show || this.view.index.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.cursorSkipUp = 0
          this.data.cursorSkipDown = 0
          this.data.skip = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
        }
        this.getData(direction, () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    // 分页读取数据
    async getData(direction, cb) {
      direction = direction || 'down'
      if (!this.config.show && !this.view.relation.show && !this.view.invite.show && !this.view.discover.show && !this.view.index.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      this.loading[this.mod] = true
      const params = {
        'sort_field': 'login_at',
        'sort': 'desc',
        'limit': this.limit,
        'skip': direction === 'up' ? this.data.cursorSkipUp : this.data.cursorSkipDown,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction
      }
      if (this.mod === 'new' || this.mod === 'index') params.sort_field = 'created_at'
      if (this.mod === 'active' || this.mod === 'discover') params.sort_field = 'login_at'
      if (this.mod === 'creator') {
        params.is_creator = 1
        params.sort_field = 'updated_at'
      }
      if (this.mod === 'invite') {
        params.sort_field = 'id'
        params.userid = this.loginUserId
        params.mod = this.mod
      }
      if (this.mod === 'follow' || this.mod === 'fans' || this.mod === 'guest') {
        if (this.view.relation.userid) params.userid = this.view.relation.userid
        params.mod = this.mod
      }
      // console.error('params', this.mod, params, direction, this.data.currentPage, this.data)
      service.listGet('user', params, (res, type) => {
        if (type === 'success') {
          let refresh = false
          if (direction === 'up') {
            this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorSkipUp = res.data.cursor_skip_up
            if (!this.data.cursorValueDown && !utils.isEmpty(res.data.cursor_value_down)) this.data.cursorValueDown = res.data.cursor_value_down
            this.data.noUpPage = utils.isEmpty(this.data.cursorValueUp)
            this.data.items = res.data.items.concat(this.data.items)
          } else if (direction === 'down') {
            if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
              if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                this.init(true)
                this.showUp = true
              } else {
                this.init()
              }
              refresh = true
            }
            this.data.currentPage++
            if (!this.data.cursorValueUp && !utils.isEmpty(res.data.cursor_value_up)) this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorValueDown = res.data.cursor_value_down
            this.data.cursorSkipDown = res.data.cursor_skip_down
            this.data.noDownPage = utils.isEmpty(this.data.cursorValueDown)
            this.data.items = this.data.items.concat(res.data.items)
          }
          if (res.data.items) {
            for (var idx in res.data.items) {
              this.users[res.data.items[idx].userid] = res.data.items[idx]
            }
          }
          if (res.data.follow && (this.mod === 'follow' || this.mod === 'fans')) {
            for (var i in res.data.follow) {
              if (this.mod === 'follow' && res.data.follow[i].from_user_id === this.loginUserId) {
                this.followState[res.data.follow[i].to_user_id] = res.data.follow[i].is_mutual
              } else if (this.mod === 'fans' && res.data.follow[i].to_user_id === this.loginUserId) {
                this.followState[res.data.follow[i].from_user_id] = res.data.follow[i].is_mutual
              }
            }
          } else if (this.mod !== 'discover') {
            this.getFollowState(res.data.items)
          }
          this.data.skip = utils.getInt(res.data.skip)
          if (this.mod === 'guest' && res.data.guest) this.data.guest = res.data.guest
          if (direction === 'up') {
            this.renderItems(this.data.items, true)
          } else {
            this.renderItems(res.data.items, refresh)
          }
          if (this.mod === 'invite') this.emit('setTotal', [res.data.total])
        } else {
          this.message(res, type)
        }
        cb && cb()
        this.loading[this.mod] = false
        if (this.view.relation.show) this.view.relation.loading = false
        if (this.view.invite.show) this.view.invite.loading = false
        if (this.view.discover.show) this.view.discover.loading = false
        if (this.view.index.show) this.view.index.loading = false
      }, false, false)
    },
    // 获取关注状态
    getFollowState(items) {
      if (!items) return
      const ids = []
      for (var i in items) {
        const userid = items[i].userid
        if (typeof this.followState[userid] === 'undefined' && parseInt(userid) !== this.loginUserId) {
          ids.push(userid)
        }
      }
      if (ids.length) {
        service.batchGet('user_follow', ids, (res, type) => {
          if (type === 'success' && res.data) {
            for (const userid in res.data) {
              this.followState[userid] = res.data[userid]
            }
            this.update()
          }
        })
      }
    },
    // 关注
    follow(userid) {
      this.followState[userid] = 1
      this.update()
      this.emit('follow', [userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = res.isMutual
        } else {
          // 回滚
          this.followState[userid] = -1
          this.message(res, type)
        }
        this.update()
      }])
    },
    // 取消关注
    unfollow(userid) {
      this.followState[userid] = -1
      this.update()
      this.emit('unfollow', [userid, (res, type) => {
        if (type === 'success') {
          this.followState[userid] = -1
        } else {
          // 回滚
          this.followState[userid] = 0
          this.message(res, type)
        }
        this.update()
      }])
    }
  }
}
