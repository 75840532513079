<template>
  <div :id="'users-' + mod + '-container'" class="padding-bottom12">
    <div class="horiz-scroll" :style="{'overflow-x': isMobile ? '' : 'hidden', 'min-height': (maxThumbHeight + 10) + 'px'}">
      <div class="scroll-works" :style="{ 'width': (data.horizWidth + 45) + 'px', 'height': (maxThumbHeight + 10) + 'px' }">
        <div v-for="(item, i) in data.items" :key="i" class="creator-card" :style="{ 'width': maxThumbWidth + 'px', 'height': maxThumbHeight + 'px' }" @click="emit('goto', ['user', { userid: item.userid }])">
          <div class="bg-33 round-top" :style="getBgStye(item)">
            <span v-if="!item.avatar" class="el-avatar el-avatar--circle noavatar xlarge">
              <i class="iconfont my-man" />
            </span>
            <el-avatar v-if="item.avatar" :size="64" :src="getAvatar(item)" />
          </div>
          <div class="ellipsis align-center size12 nickname" :style="{ width: maxThumbWidth + 'px', height: maxThumbHeight / 2 + 'px', 'padding-top': '32px' }">
            {{ item.nickname }}
          </div>
        </div>
        <div v-if="data.items.length > 2 && isMobile" class="more float-right" :style="{ 'height': maxThumbHeight + 'px', 'line-height': maxThumbHeight + 'px' }" @click="emit('goto', ['users', { type: 'new' }])">
          <i class="iconfont my-right color-cc" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/users'
export default {
  mixins: [mixins]
}
</script>
