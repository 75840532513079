<template>
  <div class="flow-container my-list">
    <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
      <div v-for="(item, i) in column.items" :key="i" class="item">
        <div class="card full" :style="{ 'width': cardWidth + 'px', 'height': ((item._more ? item._height + 30 : item._height) + 7) + 'px' }">
          <div>
            <div v-if="(item.refid && item._thumb_height)" class="work-thumb padding-top12">
              <img class="round4" :style="{'height': item._thumb_height - 15 + 'px'}" :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + item.refid + '/' + item.refid + '.png?ver=' + item.id" @click="openViewer(0, item.refid, 'thread')">
            </div>
            <div class="txt full" :style="{ 'overflow-x': 'hidden', 'overflow-y': 'hidden', 'width': cardWidth + 'px', 'height': item._raw_height + 'px' }" @click="viewThread(item.id)" v-html="getContent(item, 'p')" />
            <div v-if="item._images && item._images.length" style="padding: 5px 10px;">
              <el-image v-for="(img, x) in item._images" :key="x" :style="{'width': cardWidth - 20 + 'px', 'display': 'block'}" :src="img" lazy @click="viewImages(item._images, x, item)">
                <div slot="error" class="image-slot align-center size24 gray" :style="{'line-height': '40px'}">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </div>
            <div v-if="item._docs && item._docs.length">
              <el-row v-for="(attachment, y) in item._docs" :key="y" style="padding: 5px 10px;">
                <div class="round4 bg-28 padding4" :style="{'width': cardWidth - 20 + 'px', 'height': '50px', 'line-height': '22px'}">
                  <div class="ellipsis float-left padding-left8" :style="{'width': cardWidth - 90 + 'px'}">
                    <span>附件{{ y + 1 }}.</span>
                    {{ attachment.file_name }} <br><span class="color-88">{{ formateBytes(attachment.file_size) }}</span>
                  </div>
                  <div class="padding-top8 float-right align-right">
                    <el-button class="tap-btn" @click="downDoc(attachment)">
                      下载
                    </el-button>
                  </div>
                </div>
              </el-row>
            </div>
            <div class="info">
              <span>{{ getCreateTime(item) }} 发布</span> <span> {{ threadCount[item.id] && threadCount[item.id].view_count ? formateNums(threadCount[item.id].view_count) + ' 浏览' : '' }}</span>
            </div>
            <el-row class="foot">
              <el-col :span="12" class="l">
                评论 {{ (threadCount[item.id] && threadCount[item.id].post_count > 1) ? formateNums(threadCount[item.id].post_count - 1) : '0' }}条</el-col>
              <el-col :span="12" class="r">{{ (threadCount[item.id] && threadCount[item.id].praise_count) ? formateNums(threadCount[item.id].praise_count) : '0' }} 赞 | {{ (threadCount[item.id] && threadCount[item.id].share_count) ? formateNums(threadCount[item.id].share_count) : '0' }} 转发</el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/thread'
export default {
  mixins: [mixins]
}
</script>
