<template>
  <el-dialog
    ref="servicesDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :top="marginTop"
    :show-close="false"
    custom-class="pop_page noscroll header_40 servicesDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="close" />
        &nbsp;
      </div>
      <div class="middle">
        全部服务
      </div>
      <div class="float-right align-right">
        <el-button class="tap-btn" @click="goto('setup')">
          <i class="iconfont my-dot-more" />
        </el-button>
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="close" />
      </div>
    </div>
    <div v-show="config.show" class="my-container bg-22" :style="{ 'width': '100%', 'height': 'calc(100% - 50px)', 'overflow': 'hidden auto' }">
      <div class="user-btns padding-top8">
        <div class="bg-22 padding-top8 padding-bottom12">
          <div class="padding4 align-left size14 color-99"> &nbsp; 服务</div>
          <el-row>
            <el-col :span="5">
              <el-badge v-if="loginStatus" :value="formateNums(state.unread.total)" :hidden="!state.unread.total">
                <el-button class="tap-btn iconfont my-message" @click="goto('message')" />
                <div>消息</div>
              </el-badge>
            </el-col>
            <el-col :span="5"><el-button class="tap-btn iconfont my-task" @click="goto('task')" /><div>任务</div></el-col>
            <el-col :span="5"><el-button class="tap-btn iconfont my-sign" @click="goto('sign')" /><div>签到</div></el-col>
            <el-col :span="5"><el-button class="tap-btn iconfont my-items" @click="goto('items')" /><div>物品</div></el-col>
            <el-col :span="5"><el-button class="tap-btn iconfont my-worth" @click="goto('wallet')" /><div>晶币</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="5"><el-button class="tap-btn iconfont my-orders" @click="goto('order')" /><div>订单</div></el-col>
            <el-col :span="5"><el-button class="tap-btn iconfont my-paper" @click="openSelecter({ 'title': '图纸', 'table': 'paper', 'keyfield': 'paperid', 'maxSelectCount': 20, 'simple': false, 'showActions': false, 'batchDelete': true })" /><div>图纸</div></el-col>
            <el-col :span="5"><el-button class="tap-btn iconfont my-sn" @click="openSelecter({ 'title': '系列号', 'table': 'sn', 'keyfield': 'snid', 'maxSelectCount': 1, 'simple': true })" /><div>系列号</div></el-col>
            <el-col :span="5"><el-button class="tap-btn iconfont my-vip" @click="goto('vip')" /><div>VIP会员</div></el-col>
            <el-col :span="5"><el-button class="tap-btn iconfont my-invite" @click="goto('invite')" /><div>邀请好友</div></el-col>
          </el-row>
        </div>
      </div>
      <div class="user-btns padding-top8">
        <div class="bg-22 padding-top8 padding-bottom12">
          <div class="padding4 align-left size14 color-99"> &nbsp; 作品</div>
          <el-row>
            <el-col :span="6"><el-button class="tap-btn iconfont my-material" @click="emit('openDraft', ['draft'])" /><div>本地草稿</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-pixel" @click="emit('openResource', ['work'])" /><div>我的作品</div></el-col>
            <!--el-col :span="6"><el-button class="tap-btn iconfont my-ai-paint" @click="emit('openResource', ['paint'])" /><div>AI作品</div></el-col-->
          </el-row>
        </div>
      </div>
      <div class="user-btns padding-top8">
        <div class="bg-22 padding-top8 padding-bottom12">
          <div class="padding4 align-left size14 color-99"> &nbsp; 创作</div>
          <el-row>
            <el-col :span="6"><el-button class="tap-btn iconfont my-pixel" @click="view.createNewfile = true" /><div>新建画布</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-gridsfy" @click="emit('openPopPage', [{ 'type': 'toBrickfy' }])" /><div>定制拼图</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-post-add" @click="emit('openPost')" /><div>发布主题</div></el-col>
            <!--el-col :span="6"><el-button class="tap-btn iconfont my-avatar" @click="emit('openPopPage', [{ 'type': 'toPixel' }])" /><div>AI像素画</div></el-col-->
          </el-row>
          <!--el-row>
            <el-col :span="6"><el-button class="tap-btn iconfont my-ai-paint" @click="emit('openAiPaint')" /><div>AI作画</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-cartoon" @click="emit('openPopPage', [{ 'type': 'toCartoon' }])" /><div>AI动漫</div></el-col>
          </el-row-->
        </div>
      </div>
      <div class="user-btns padding-top8">
        <div class="bg-22 padding-top8 padding-bottom12">
          <div class="padding4 align-left size14 color-99"> &nbsp; 内容</div>
          <el-row>
            <el-col :span="6"><el-button class="tap-btn iconfont my-pixel" @click="goto('works')" /><div>作品</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-zone" @click="goto('zone')" /><div>圈子</div></el-col>
            <!--el-col :span="6"><el-button class="tap-btn iconfont my-ai-paint" @click="goto('aiart')" /><div>AI作画</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-blockchain" @click="goto('nft')" /><div>数字藏品</div></el-col-->
          </el-row>
        </div>
      </div>
      <div class="user-btns padding-top8">
        <div class="bg-22 padding-top8 padding-bottom12">
          <div class="padding4 align-left size14 color-99"> &nbsp; 互动</div>
          <el-row>
            <el-col :span="6"><el-button class="tap-btn iconfont my-down-round" @click="goto('download')" /><div>下载</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-blank-star" @click="goto('favor')" /><div>收藏</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-praise-blank" @click="goto('praise')" /><div>点赞</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-footprint" @click="goto('footprint')" /><div>足迹</div></el-col>
          </el-row>
        </div>
      </div>
      <div class="user-btns padding-top8">
        <div class="bg-22 padding-top8 padding-bottom12">
          <div class="padding4 align-left size14 color-99"> &nbsp; 其他</div>
          <el-row>
            <el-col :span="6"><el-button class="tap-btn iconfont my-personal" @click="goto('profile')" /><div>个人设置</div></el-col>
            <el-col :span="6"><el-button class="tap-btn iconfont my-setting" @click="goto('account')" /><div>账号设置</div></el-col>
          </el-row>
        </div>
      </div>
      <div class="user-btns padding-top8" />
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
export default {
  components: {},
  mixins: [mixins],
  computed: {
    config() {
      return this.view.services
    }
  },
  methods: {
    formateNums: utils.formateNums,
    handle(act, data) {
      this.emit(act, data)
    },
    // 打开选择器
    openSelecter(config) {
      this.emit('openSelecter', [config])
    },
    logout() {
      this.emit('logout')
      this.goto('works')
    },
    close() {
      this.config.show = false
      this.goto('user')
    }
  }
}
</script>
