<template>
  <el-dialog
    ref="vipDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    :custom-class="'pop_page noscroll'"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        VIP会员
      </div>
      <div class="float-right align-right">
        <el-button class="tap-btn iconfont my-invite size20" @click="goto('invite')" />
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div ref="header-card" class="padding8">
        <div class="my-row round8" style="height: 80px;">
          <div class="float-left padding-top4">
            <span v-if="!(user.avatar && !config.loading)" class="el-avatar el-avatar--circle noavatar large">
              <i class="iconfont my-man" />
            </span>
            <el-avatar v-if="user.avatar && !config.loading" :size="48" :src="getAvatar()" />
            <!-- <span v-if="isVip" class="iconfont my-vip vip-tag opacity-bg yellow" /> -->
          </div>
          <div class="info padding-top4">
            <span class="name margin-left4">{{ user.nickname || view.anonymity }}</span>
            <span class="addition opacity-bg iconfont my-vip color-99" :class="{'yellow': isVip}" />
            <span v-if="false" class="addition opacity-bg">Lv.3</span> <br>
            <span class="padding-left4 size12 yellow">{{ isVip ? formateDate(user.expired_at) + '到期，购买后有效期顺延' : '加入VIP享受更多权益吧' }}</span>
          </div>
        </div>
      </div>
      <div class="horiz-scroll" :style="{'min-height': (111 * 1.2 + 10) + 'px' }">
        <div class="scroll-works" :style="{'width': scrollWidth + 'px', 'height': 111 * 1.2 + 'px' }">
          <div v-for="(group, i) in groups" :key="i" @click="changeGroup(i)">
            <div v-if="i > 0" class="round8 padding-top12" :class="{'bg-28 color-dd': curGroupid !== i, 'bg-blue color-ff': curGroupid === i}" :style="{ width: colSize + 'px', height: 111 * 1.2 + 'px', margin: '0 5px'}">
              <el-col class="align-center margin-top12 size14 bold">{{ group.name }}</el-col>
              <el-col class="align-center margin-top12 size18 bold">¥{{ formateMoney(group.fee) }}</el-col>
              <el-col class="align-center margin-top8 size12" :class="{'color-99': curGroupid !== i, 'color-ff': curGroupid === i}"> {{ '已打' + (group.discount / 10) + '折' }}</el-col>
            </div>
          </div>
        </div>
      </div>
      <div class="my-row round8 margin-left8 margin-right8 margin-top4">&nbsp;会员权益对比</div>
      <div class="padding8 my-table">
        <el-table :data="tableData" stripe border style="width: 100%" :height="tableHeight">
          <el-table-column fixed="left" prop="title" label="权益" width="120" />
          <el-table-column v-for="(group, i) in groups" :key="i" :prop="'k_' + group.groupid" :label="group.name" :width="colWidth[i]" />
        </el-table>
      </div>
    </div>
    <div slot="footer" class="footer padding-top8" style="position: absolute;">
      <el-button v-if="payBtn" type="light" class="btn-ok" style="width:220px;" @click="payIt">
        {{ payBtn }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import conf from '@/js/conf/conf'
import utils from '@/js/utils'
import service from '@/js/service'
import { mixins } from '@/mixins/common'
export default {
  components: {},
  mixins: [mixins],
  data() {
    let container_width, container_height, colWidth
    if (this.state.platform.type === 'desktop') {
      container_width = 632
      container_height = 632
      colWidth = [78, 80, 80, 74, 84, 84]
    } else {
      container_width = utils.width()
      container_height = utils.height()
      colWidth = [90, 90, 90, 90, 90, 90]
      if (container_width > 620) colWidth = ['', '', '', '', '', '']
    }
    const data = {
      mainHost: conf.hosts().mainHost,
      worksHost: conf.hosts().worksHost,
      scrollTop: 0,
      container_width: container_width,
      tableHeight: container_height - 390 + (this.state.platform.type === 'desktop' ? 8 : 0),
      colWidth: colWidth,
      scrollWidth: 618,
      colSize: 111,
      curGroupid: 3,
      groups: [],
      user: {},
      tableData: [],
      powers_map: {
        'regular_fee': '原价',
        'discount': '折扣',
        'fee': '现价',
        'scale': '分销提成',
        'have_ad': '广告',
        'have_water_mark': '导出水印',
        'create_album': '创建专辑',
        // 'create_draft': '草稿',
        // 'publish_work': '发布百格画',
        // 'max_cooperate_works': '多人作画',
        // 'create_paint': 'AI作画',
        'export_pixel': '导出像素画',
        'export_gridy': '导出百格画',
        'export_animation': '导出动画',
        'export_high_gridy': '高清导出',
        'export_advance': '高级导出',
        // 'diy_discount': '定制商品折扣',
        // 'nft_discount': '数字藏品折扣',
        'advance_service': '专属客服',
        'offline_activity': '线下活动',
        'prize_scale': '签到奖励'
      }
    }
    return data
  },
  computed: {
    isVip() {
      return this.user.groupid > 10 && new Date(this.user.expired_at) > new Date()
    },
    payBtn() {
      if (!this.groups.length || !this.curGroupid) return ''
      if (this.groups[this.curGroupid]) {
        return '立即购买' + this.groups[this.curGroupid].name + ' ¥' + utils.formateMoney(this.groups[this.curGroupid].fee)
      } else {
        return ''
      }
    },
    style() {
      const style = {}
      let bg = this.getBackground()
      // if (!bg) bg = this.worksHost + utils.getDefaultBg(this.user.userid || 0) + '/background'
      if (!bg) bg = utils.getDefaultBg(this.user.userid || 0)
      if (bg && this.scrollTop < 100 && !this.view.vip.loading) {
        style['background-image'] = 'url(' + bg + ')'
        style['background-position'] = 'center center'
        style['background-size'] = '100% auto'
        style['background-repeat'] = 'no-repeat'
      } else {
        style['padding-top'] = '30px'
      }
      return style
    },
    config() {
      return this.view.vip
    }
  },
  mounted() {
    this.view.vip.loading = false
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.getUser()
    this.getGroups()
    window.addEventListener('resize', this.calcSize)
  },
  methods: {
    changeGroup(groupid) {
      this.curGroupid = groupid
      this.emit('traceEvent', ['changeVipGroupId', '', { groupid: groupid }])
    },
    payIt() {
      if (!this.groups.length || !this.curGroupid || !this.groups[this.curGroupid]) return
      const order = {
        'amount': this.groups[this.curGroupid].fee / 100,
        'groupId': this.groups[this.curGroupid].groupid,
        'title': this.groups[this.curGroupid].name,
        'type': 4,
        'isAnonymous': false
      }
      const pay_cb = (success) => {
        if (success) {
          this.getUser()
          this.view.user.renew = utils.time('time')
          this.alert('购买成功')
          this.emit('traceEvent', ['finishVipOrder', '', { groupid: this.curGroupid }])
        }
      }
      this.emit('traceEvent', ['createVipOrder', '', { groupid: this.curGroupid }])
      service.createOrder(order, (res, type) => {
        if (type === 'success') {
          order.data = res
          order.orderid = res.id || 0
          order.name = order.title
          this.showPayment(order, pay_cb)
        } else {
          this.message(res, type)
        }
      })
    },
    showPayment(order, cb) {
      this.view.pop.title = '支付'
      this.view.pop.type = 'payment_orders'
      this.view.pop.data = order || {}
      this.view.pop.show = true
      this.view.pop.fn = cb
    },
    setTableData() {
      if (!this.groups.length) return []
      const rows = {}
      Object.keys(this.powers_map).map((field) => {
        rows[field] = { title: this.powers_map[field] }
      })
      const createRow = (data) => {
        Object.keys(data).map((k) => {
          if (this.powers_map[k]) {
            let val
            if (k === 'regular_fee' || k === 'fee') {
              val = data[k] ? '¥' + utils.formateMoney(data[k]) : '免费'
            } else if (k === 'scale') {
              val = data[k] + '%'
            } else if (k === 'prize_scale') {
              val = data[k] + '倍'
            } else if (['export_pixel', 'export_gridy', 'export_animation', 'export_high_gridy', 'export_advance'].indexOf(k) >= 0) {
              val = data[k] === 0 ? 'x' : (data[k] + '晶钻')
            } else if (['create_draft', 'publish_work', 'create_paint'].indexOf(k) >= 0) {
              val = data[k] === 0 ? 'x' : (data[k] + '晶钻')
            } else if (k === 'create_album') {
              val = data[k] === 0 ? 'x' : (data[k] + '晶钻')
            } else if (k.indexOf('discount') >= 0) {
              val = (data[k] >= 50 && data[k] < 100) ? (data[k] / 10) + '折' : '-'
            } else if (k === 'have_ad' || k === 'have_water_mark' || k === 'advance_service' || k === 'offline_activity') {
              val = data[k] ? '√' : 'x'
            } else {
              val = data[k]
            }
            rows[k]['k_' + data.groupid] = val
          }
        })
      }
      for (const i in this.groups) {
        createRow(this.groups[i])
      }
      this.tableData = Object.values(rows)
    },
    formateDate(time) {
      if (!time) return ''
      return utils.date('date', 0, new Date(time))
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    getElementHeight(refid) {
      return this.$refs[refid] ? parseInt(window.getComputedStyle(this.$refs[refid]).height) : 0
    },
    getAvatar() {
      const ver = Math.round(new Date(this.user.avatar_at) / 1000)
      if (this.user.avatar) return service.getCosUrl(this.user.avatar, 'avatar', ver) || ''
    },
    getBackground() {
      const ver = Math.round(new Date(this.user.background_at) / 1000)
      if (this.user.background) return service.getCosUrl(this.user.background, 'background', ver) || ''
    },
    getUser() {
      this.view.vip.loading = true
      this.emit('getUser', [this.loginUserId, (user) => {
        if (user) this.user = user
        this.view.vip.loading = false
        this.update()
      }])
    },
    getGroups() {
      this.view.vip.loading = true
      const params = {
        'sort_field': 'group_powerid',
        'sort': 'asc',
        'limit': 10,
        'cursor_value': 0,
        'direction': 'down',
        'simple': 1,
        'between_field': 'group_powerid',
        'between_value': '10,0'
      }
      service.listGet('group_power', params, (ret, type) => {
        if (type === 'success' && ret.data && ret.data.items && ret.data.items.length) {
          this.view.vip.loading = false
          this.groups = ret.data.items
          this.calcSize()
          this.setTableData()
          this.update()
        }
      }, false, false)
    },
    calcSize() {
      if (this.state.platform.type === 'desktop') {
        this.container_width = 632
        this.container_height = 632
        this.colWidth = [78, 80, 80, 74, 84, 84]
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
        this.colWidth = [90, 90, 90, 90, 90, 90]
        if (this.container_width > 620) this.colWidth = ['', '', '', '', '', '']
      }
      this.tableHeight = this.container_height - 390 + (this.state.platform.type === 'desktop' ? 8 : 0)
      this.colSize = Math.max(((this.container_width - 5) / (this.groups.length - 1)) - 11, 111)
      this.scrollWidth = ((this.groups.length - 1) * (this.colSize + 11) + 5)
    }
  }
}
</script>
