<template>
  <el-dialog :title="title" :visible.sync="config.show" :fullscreen="platform.type !== 'desktop'" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :top="marginTop" custom-class="pop_page popPageDialog">
    <div slot="title" class="header" :style="platform.type !== 'desktop' ? containerStyle : ''">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="close()" />
        &nbsp;
      </div>
      <div class="middle">
        {{ title }}
      </div>
      <div class="float-right align-right">
        <el-button v-if="config.type === 'paint'" class="tap-btn iconfont my-ai-paint size20" title="AI作品" @click="emit('openResource', 'paint')" />
        <el-button v-if="config.type === 'toCartoon'" class="tap-btn iconfont my-share size20 clipboard" :data-clipboard-text="' 快来体验AI动漫形象吧！ ' + mainHost + '#/?mod=popPage&type=toCartoon&inviteid=' + loginUserId" @click="share" />
        <el-button v-if="config.type === 'toBrickArt'" class="tap-btn iconfont my-share size20 clipboard" :data-clipboard-text="' 快来看看百变拼图吧！ ' + mainHost + '#/?mod=popPage&type=toBrickArt&inviteid=' + loginUserId" @click="share" />
        <el-button v-if="config.type === 'toPixel'" class="tap-btn iconfont my-share size20 clipboard" :data-clipboard-text="' 快来体验AI像素画/像素头像吧！ ' + mainHost + '#/?mod=popPage&type=toPixel&inviteid=' + loginUserId" @click="share" />
        <el-button v-if="config.type === 'toBrickfy'" class="tap-btn iconfont my-share size20 clipboard" :data-clipboard-text="' 快来体验定制拼图吧！ ' + mainHost + '#/?mod=popPage&type=toBrickfy&inviteid=' + loginUserId" @click="share" />
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="close()" />
        &nbsp;
      </div>
    </div>
    <div :class="{'my-container': platform.type !== 'desktop'}" :style="containerStyle">
      <div v-if="loginStatus && (config.type === 'bindPhone' || config.type === 'unbindPhone' || config.type === 'setPassword' || config.type === 'setPayPassword' || config.type === 'realVerify' || config.type === 'realVerifyInfo') " class="my-block">
        <el-form ref="formWidget" v-loading="config.loading" class="my-form" label-width="80px" :inline="false" :model="form" :rules="rules" element-loading-background="rgba(0, 0, 0, 0.6)">
          <el-form-item v-if="config.type !== 'bindPhone' && config.type !== 'realVerifyInfo'" label="手机号" prop="phone">
            <el-input v-model.trim="form.phone" type="input" maxlength="11" :placeholder="'请输入手机号 ' + (config.data.mobile || '') " clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.phone = form.phone.replace(/[^\d.]/g,'')" />
          </el-form-item>
          <el-form-item v-if="config.type !== 'bindPhone' && config.type !== 'realVerifyInfo'" label="验证码" prop="code" class="sms-item">
            <el-input v-model.trim="form.code" type="input" maxlength="6" placeholder="请输入验证码" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.code = form.code.replace(/[^\d.]/g,'')" />
            <div class="sms-send" :class="{'is-disabled': !form.phone || state.countDownNum}" @click="sendSMS()">
              {{ state.countDownNum ? state.countDownNum : '获取验证码' }}
            </div>
          </el-form-item>
          <el-form-item v-if="config.type === 'bindPhone'" label="新手机号" prop="phone">
            <el-input v-model.trim="form.phone" type="input" maxlength="11" :placeholder="'请输入新手机号'" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.phone = form.phone.replace(/[^\d.]/g,'')" />
          </el-form-item>
          <el-form-item v-if="config.type === 'bindPhone'" label="验证码" prop="code" class="sms-item">
            <el-input v-model.trim="form.code" type="input" maxlength="6" placeholder="请输入验证码" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.code = form.code.replace(/[^\d.]/g,'')" />
            <div class="sms-send" :class="{'is-disabled': !form.phone || state.countDownNum}" @click="sendSMS()">
              {{ state.countDownNum ? state.countDownNum : '获取验证码' }}
            </div>
          </el-form-item>
          <el-form-item v-if="config.type === 'setUsername'" label="用户名" prop="username">
            <el-input v-model.trim="form.username" type="input" maxlength="18" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'setPassword' || config.type === 'setPayPassword'" :label="(config.type === 'setPayPassword' ? '支付': '登录') + '密码'" prop="password">
            <el-input v-model.trim="form.password" type="password" :maxlength="config.type === 'setPayPassword' ? 6 : 20" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'setPassword' || config.type === 'setPayPassword'" label="确认密码" prop="repassword">
            <el-input v-model.trim="form.repassword" type="password" :maxlength="config.type === 'setPayPassword' ? 6 : 20" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerify'" label="真实姓名" prop="realname">
            <el-input v-model.trim="form.realname" type="input" maxlength="18" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerify'" label="身份证号" prop="idcard">
            <el-input v-model.trim="form.idcard" type="input" maxlength="18" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerifyInfo'" label="真实姓名">
            {{ config.data.realname }}
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerifyInfo'" label="身份证号">
            {{ config.data.identity }}
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerifyInfo'" label="状态">
            <span v-if="config.data.verify_status === 0">未认证</span>
            <span v-if="config.data.verify_status === 1" class="yellow">认证中</span>
            <span v-if="config.data.verify_status === 2" class="green">已认证</span>
            <span v-if="config.data.verify_status === -1" class="red">认证失败</span>
          </el-form-item>
          <el-form-item label="">
            <el-button v-if="config.type !== 'realVerifyInfo'" class="submit" @click="submitForm('formWidget')">
              {{ config.type === 'bindPhone' ? '绑定' : '提交' }}
            </el-button>
          </el-form-item>
          <div v-if="config.type === 'realVerify'" class="article">
            提示：<br>
            1、我们将收集您的姓名和身份证号用于实名认证<br>
            2、实名认证通过后，可获得完整的服务和体验
          </div>
        </el-form>
      </div>
      <div v-if="config.type === 'chainAccount'">
        <div class="padding15">
          <b>区块链账户地址</b>
        </div>
        <div class="block-round clipboard" :data-clipboard-text="config.data.account || ''" @click="clipboardCopy('chain_account')">
          {{ config.data.account || '' }}  <i class="iconfont my-copy size16 color-bb" />
        </div>
        <div class="padding15">
          <b>区块链浏览器</b>
        </div>
        <div class="block-round clipboard" :data-clipboard-text="'https://tianzhou.wenchang.bianjie.ai/'" @click="clipboardCopy('chain_browser')">
          https://tianzhou.wenchang.bianjie.ai/ <i class="iconfont my-copy size16 color-bb" />
        </div>
      </div>
      <div v-if="config.type === 'feedback'">
        <el-form v-loading="config.loading" class="my-form" label-width="80px" element-loading-background="rgba(0, 0, 0, 0.6)">
          <el-form-item label="类型" class="my-el-form-item">
            <el-select v-model.trim="feedback.type" placeholder="请选择...">
              <el-option
                v-for="item in feedback.typeOpts"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="主题">
            <el-input v-model.trim="feedback.title" class="my-from-item" maxlength="255" placeholder="请输入反馈主题" clearable />
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model.trim="feedback.content" class="my-from-item" maxlength="2048" type="textarea" placeholder="请输入反馈内容" rows="10" resize="false" />
          </el-form-item>
          <el-form-item label="联系人">
            <el-input v-model.trim="feedback.name" class="my-from-item" maxlength="20" placeholder="请留下您的名字" clearable />
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model.trim="feedback.contact" class="my-from-item" maxlength="100" placeholder="请留下联系方式" clearable />
          </el-form-item>
          <el-form-item label="">
            <el-button class="submit" @click="sendFeedback()">提 交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="config.type === 'aboutUs'" class="aboutus">
        <div class="icon">
          <img src="@/assets/icon.png" title="Gridy.Art 百格画" width="64">
        </div>
        <div class="txt">
          Gridy.Art 百格画 <br><br>
          提供像素画、拼图、钻石画创作工具<br>致力于打造数字内容生态平台及Web3.0社区<br><br>
          <el-link :href="mainHost" target="_blank" class="blue">访问Gridy.Art网站</el-link><br><br><br>
        </div>
        <div class="txt gray copyright">
          ©2023 Gridy.Art
        </div>
      </div>
      <div v-if="config.type === 'about'" class="aboutus">
        <div class="icon">
          <img src="@/assets/icon.png" title="Gridy.Art 百格画" width="64"><br><br>
          Gridy.Art 百格画 <br><br>
        </div>
        <div class="txt align-left" :style="{'padding': isDesktop ? '10px 80px' : '10px' }">
          &nbsp; &nbsp; &nbsp; 北京网格跳动科技有限公司成立于2003年；专注于像素画内容生态平台建设，打造像素IP，传播像素文化；网格跳动提供跨平台领先的像素画、拼图、钻石画、3D体素模型设计工具，应用人工智能、区块链技术打造闭环的内容创作、分享、分发、版权保护、商业应用、虚实结合的数字内容生态平台。<br><br>
        </div>
        <div class="txt gray copyright">
          ©2023 Gridy.Art
        </div>
      </div>
      <div v-if="config.type === 'contact'" class="aboutus">
        <div class="icon">
          <img src="@/assets/icon.png" title="Gridy.Art 百格画" width="64"><br><br>
          Gridy.Art 百格画 <br><br>
        </div>
        <div class="txt align-left" :style="{'padding': isDesktop ? '10px 80px 10px 150px' : '' }">
          <div style="width: 340px;margin: 0 auto;">
            公司：北京网格跳动科技有限公司<br>
            地址：北京市海淀区中关村大街18号11层1129-45<br>
            联系人：吴先生<br>
            电话：18120825991<br>
            邮箱：389193@qq.com
          </div>
        </div>
        <div class="txt gray copyright">
          ©2023 Gridy.Art
        </div>
      </div>
      <div v-if="config.type === 'checkUpdate'" class="aboutus">
        <div class="icon">
          <img src="@/assets/icon.png" title="Gridy.Art 百格画" width="64">
        </div>
        <div class="txt new-version">
          Ver {{ ver }} <br>
          <span v-if="platform.value > 10">
            {{ state.newVersion ? '有新版本：' + state.newVersion.ver : '' }}
            <br><el-link v-if="state.newVersion" :href="downloadUrl" target="_blank" class="yellow">现在下载</el-link>
            <el-button v-if="!state.newVersion" size="mini" @click="checkUpdate">检查更新</el-button> <br>
          </span>
          <span v-if="platform.value <= 10"> 最新版</span>
          <br><br><br>
        </div>
        <div class="txt gray copyright">
          ©2023 Gridy.Art
        </div>
      </div>
      <div v-if="config.type === 'agreement' || config.type === 'privacy'" class="article text" v-html="settings[config.type] || ''" />
      <div v-if="config.type === 'nftnotice'" class="article padding4">
        <div class="block-round">
          <b>发行信息</b>
        </div>
        <div class="padding15">
          <el-row>
            <el-col :span="19"><b>发行数量</b></el-col>
            <el-col :span="5">{{ publish.total }} 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19"><span class="float-left" style="width:90px">· 公开发售</span> <span class="float-left">({{ getDate(publish.sell_at) }})</span></el-col>
            <el-col :span="5">{{ publish.sell_nums }} 份</el-col>
          </el-row>
          <el-row v-if="publish.advance_sell">
            <el-col :span="19"><span class="float-left" style="width:90px">&nbsp;&nbsp; · 优先购</span> <span class="float-left">({{ getDate(publish.sell_at - 3600) }})</span></el-col>
          </el-row>
          <el-row v-if="publish.preorder">
            <el-col :span="19"><span class="float-left" style="width:90px">&nbsp;&nbsp; · 开放申购</span> <span class="float-left">({{ getDate(publish.sell_at - 7 * 86400) }})</span></el-col>
          </el-row>
          <el-row v-if="publish.compound">
            <el-col :span="19"><span class="float-left" style="width:90px">· 开放铸造</span> <span class="float-left">({{ getDate(publish.compound_at) }})</span></el-col>
            <el-col :span="5">{{ publish.compound_nums }} 份</el-col>
          </el-row>
          <el-row v-if="publish.paradrop">
            <el-col :span="19"><span class="float-left" style="width:90px">· 免费空投</span> <span class="float-left">({{ getDate(publish.paradrop_at) }})</span></el-col>
            <el-col :span="5">{{ publish.paradrop_nums }} 份</el-col>
          </el-row>
          <el-row v-if="publish.blind">
            <el-col :span="19">· 盲盒预留</el-col>
            <el-col :span="5">{{ publish.blind_nums }} 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 活动预留</el-col>
            <el-col :span="5">8 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 创作者或创作机构预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 版权研究及保护预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 第三方商业授权预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 公开展览展示预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 站内二次创作预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 电子游戏应用预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 拼图玩具应用预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 拼图玩具应用预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 实物印刷应用预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 电商平台应用预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 出版物应用预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· 公开拍卖预留</el-col>
            <el-col :span="5">1 份</el-col>
          </el-row>
        </div>
        <div class="block-round">
          <b>购买须知</b>
        </div>
        <div class="text padding12" v-html="settings[config.type] || ''" />
      </div>
      <div v-if="config.type === 'publishInfo'" class="article padding4">
        <div class="block-round">
          <b>作品简介</b>
        </div>
        <div v-if="publish.story" class="text padding12">
          {{ publish.story }}
        </div>
        <div v-if="publish.images && publish.images.length" class="block-round">
          <el-row>
            <el-col :span="24"><b>作品故事</b></el-col>
          </el-row>
        </div>
        <div class="padding12">
          <div v-for="(img, i) in publish.images" :key="i" class="images">
            <img :src="img">
          </div>
          <br>
        </div>
      </div>
      <div v-if="config.type === 'publishPower'" class="article padding4">
        <div class="block-round">
          <b>权益列表</b>
        </div>
        <div class="padding15">
          <ul>
            <li v-for="(powerid, idx) in publish.powers" v-show="powers[powerid]" :key="idx" class="line-height-18">
              · {{ powers[powerid] ? (powers[powerid].name || '') : '' }}
            </li>
          </ul>
        </div>
        <div v-if="settings['powernotice']" class="block-round">
          <b>权益说明</b>
        </div>
        <div class="padding15" v-html="settings['powernotice']" />
      </div>
      <div v-if="config.type === 'publishCert'" class="article text">
        <span>{{ publish.chain_data }}</span>
      </div>
      <div v-if="config.type === 'download'" class="article padding4 align-center">
        <div>
          <div v-if="config.data.res.length" class="block-round margin-top12 margin-bottom12">
            请长按保存图片
          </div>
          <div v-for="(re, i) in config.data.res" :key="i" class="padding12">
            <img :src="re[0]" :style="{'max-width': maxImageWidth + 'px'}">
          </div>
          <div class="margin-top4">
            使用Gridy.Art客户端可下载更多文件
          </div>
        </div>
      </div>
      <div v-if="config.type === 'paint'" v-loading="config.loading" element-loading-background="rgba(0, 0, 0, 0.6)">
        <div style="overflow: hidden;" :style="{'height': isDesktop ? '85px' : '105px'}">
          <div class="post-row">
            <el-input v-model="paint.content" type="textarea" class="post-input" maxlength="120" :rows="isDesktop ? 3 : 4" resize="false" :placeholder="paint.placeholder" />
          </div>
          <div style="width: 100%;text-align:right;position: relative;padding-right:10px;top:-30px;" class="color-99 size12">
            <div class="float-right round4 bg-22" style="padding: 1px 8px;line-height:22px;">{{ txtCount }}</div>
          </div>
        </div>
        <el-row class="post-row">
          <div style="width:100%;">
            <div class="float-left margin-top8" style="width: 150px;">热门效果关键词</div>
            <div class="float-right">
              <el-button v-if="!isDesktop" class="tap-btn bg-22" style="width: 30px;" @click="paint.largeMod = !paint.largeMod">
                <i v-if="!paint.largeMod" class="iconfont my-open-down color-ee size16" />
                <i v-if="paint.largeMod" class="iconfont my-close-up color-ee size16" />
              </el-button>
            </div>
          </div>
        </el-row>
        <div class="post-row">
          <el-row style="overflow-x: hidden;overflow-y: auto;" :style="{'height': isDesktop ? '' : paint.largeMod ? '' : '85px'}">
            <div v-for="tag in paint.tags" :key="tag" class="tag bg-gray white margin-top4 margin-bottom4 margin-right8 float-left size14" @click="addPaintContent(tag)">{{ tag }}</div>
          </el-row>
        </div>
        <el-row class="post-row padding-top12">
          风格 {{ styles[paint.styleid] ? '(' + styles[paint.styleid][1] + ')' : '' }}
        </el-row>
        <div style="overflow-y: hidden;overflow-x: auto;" :style="{'height': paint.thumbFactor * 160 + 20 + 'px'}">
          <div class="padding-left4" :style="{'width': styles.length * paint.thumbFactor * 120 + styles.length * 9 + 'px'}">
            <el-col v-for="(item, i) in styles" :key="i" class="padding4 margin-right8" :style="{'width': paint.thumbFactor * 120 + 'px', 'height': paint.thumbFactor * 160 + 'px'}">
              <div class="round4" :style="{'width': paint.thumbFactor * 120 + 'px', 'height': paint.thumbFactor * 160 + 'px', 'background-color': 'var(--color-1b)'}" @click="paint.styleid = i">
                <el-image class="round4" :style="{'width': paint.thumbFactor * 120 + 'px', 'height': paint.thumbFactor * 160 + 'px', 'opacity': paint.styleid === i ? 1: 0.6}" fit="cover" :src="item[2]" />
              </div>
              <div class="opacity-bg align-center size12" :class="{'white': paint.styleid === i, 'color-99': paint.styleid !== i }" :style="{'width': paint.thumbFactor * 120 + 'px', 'height':'24px', 'line-height':'24px', 'position': 'relative', 'top':'-24px'}" @click="paint.styleid = i">{{ item[1] }}</div>
            </el-col>
          </div>
        </div>
        <div class="post-row padding-top8">
          比例 {{ resolutions[paint.resolutionid] ? '(' + resolutions[paint.resolutionid][1] + ')' : '' }}
        </div>
        <div class="post-row">
          <el-button v-for="(resolution, i) in resolutions" :key="i" :command="i" :type="i === paint.resolutionid ? 'light' : ''" style="padding: 10px 24px 6px 24px;" @click="paint.resolutionid = i">
            <i class="size20 iconfont" :class="resolution[2]" />
            <span class="padding-left8" style="font-size: 14px;line-height:28px;">{{ resolution[1] }}</span>
          </el-button>
        </div>
      </div>
      <div v-if="config.type === 'paint'" class="footer padding-top8" :style="{'position' : isDesktop ? 'absolute' : ''}">
        <el-button v-if="loginUserId" :disabled="!canPaint" :type="canPaint ? 'light' : ''" class="btn-ok" style="max-width:200px;margin-left: 32px;" @click="requestAiPaint()">
          立即生成
          <span class="size12">（{{ canPaint ? paintPrice + '晶钻' : '晶钻不足' }}）</span>
        </el-button>
        <el-button v-if="!loginUserId" type="light" class="btn-ok" style="max-width:200px;margin-left: 32px;" @click="emit('login')">
          立即生成
        </el-button>
      </div>
      <div v-if="config.type === 'nftorder'">
        <div class="margin-bottom12 align-center paddign12 line-height-12" style="height:160px;">
          <br><br>
          <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 1" class="green"><i class="iconfont my-ok size64 green" /><br>交易成功</span>
          <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status < 0" class="color-99"><i class="iconfont my-fail size64 color-99" /><br>{{ order.pay_status === -1 ? '支付失败' : (order.pay_status === -2 ? '已取消' : (order.pay_status === -3 ? '已过期' : '')) }}</span>
          <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 0" class="yellow"><i class="iconfont my-pay size64 yellow" /><br>待付款</span>
          <span v-if="order.type === 1 && order.preorder_status === 0" class="yellow"><i class="iconfont my-remain size64 yellow" /><br>等待抽签</span>
          <span v-if="order.type === 1 && order.preorder_status === -1" class="red"><i class="iconfont my-del size64 red" /><br>未中签</span>
        </div>
        <div class="block-round margin-bottom12" style="height:140px;">
          <div class="float-left" style="height:120px;width: 120px;">
            <img v-if="order.workid" :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + order.workid + '/' + order.workid + '.png?ver=' + order.update_at" class="round4" style="max-width: 120px;max-height: 120px;" :style="imgStyle(order.work)">
          </div>
          <div class="float-left margin-left12" style="margin-top: 30px;">
            <el-row class="padding-bottom4">
              {{ order.publish ? order.publish.name : '' }}
            </el-row>
            <el-row class="padding-bottom4">
              <div v-if="order.publish" class="color-99">{{ order.user.nickname || '' }}</div>
            </el-row>
          </div>
        </div>
        <div class="block-round line-height-20 margin-bottom12">
          <el-row v-if="orderTypes[order.type]">
            <el-col :span="8" class="color-99">订单类型</el-col>
            <el-col :span="16" class="align-right">
              {{ orderTypes[order.type] }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">订单金额</el-col>
            <el-col :span="16" class="align-right">
              ¥{{ formateMoney(order.amount) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">交易数量</el-col>
            <el-col :span="16" class="align-right">
              1
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">创建时间</el-col>
            <el-col :span="16" class="align-right">
              {{ getDate(order.add_at) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">订单编号</el-col>
            <el-col :span="16" class="align-right">
              <div class="clipboard" :data-clipboard-text="order.order_sn" @click="clipboardCopy('order_sn')">
                {{ order.order_sn }} <i class="iconfont my-copy size16 color-bb" />
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">支付方式</el-col>
            <el-col :span="16" class="align-right">
              {{ order.pay_type === 0 ? '晶币支付' : (order.pay_type === 1 ? '晶币支付' : (order.pay_type === 2 ? '微信支付' : (order.pay_type === 12 ? '支付宝支付' : '其他'))) }}
            </el-col>
          </el-row>
          <el-row v-if="order.pay_status">
            <el-col :span="8" class="color-99">付款时间</el-col>
            <el-col :span="16" class="align-right">
              {{ order.pay_at ? getDate(order.pay_at) : '-' }}
            </el-col>
          </el-row>
          <!-- <el-row v-if="order.pay_type !== -1">
            <el-col :span="8" class="color-99">状态</el-col>
            <el-col :span="16" class="align-right">
              <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 1">已付款</span>
              <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status < 0" class="color-99">{{ order.pay_status === -1 ? '支付失败' : (order.pay_status === -2 ? '已取消' : (order.pay_status === -3 ? '订单过期' : '')) }}</span>
              <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 0" class="blue" @click="payIt(order)">立即支付</span>
              <span v-if="order.type === 1 && order.preorder_status === 0" class="yellow">等待抽签</span>
              <span v-if="order.type === 1 && order.preorder_status === -1" class="red">未中签</span>
            </el-col>
          </el-row> -->
        </div>
        <div v-if="order.pay_status === 1" class="block-round line-height-20">
          <el-row>
            <el-col :span="8" class="color-99">藏品状态</el-col>
            <el-col :span="16" class="align-right" :class="{'red': order.nft_status === -1,'yellow': order.nft_status === 0,'green': order.nft_status === 1}">
              {{ order.nft_status === -1 ? '发放失败' : (order.nft_status === 0 ? '待发放' : (order.nft_status === 1 ? '发放成功' : '-')) }}
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="config.type === 'nftorder' && (order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 0" class="footer padding-top8" :style="{'position': isDesktop ? 'absolute' : ''}">
        <el-button type="primary" class="btn-cancel" style="max-width:200px;" @click="cancelIt(order)">
          取消订单
        </el-button>
        <el-button type="light" class="btn-ok" style="max-width:200px;" @click="payIt(order)">
          立即支付
        </el-button>
      </div>
      <div v-if="config.type === 'brickorder'">
        <div class="margin-bottom12 align-center paddign12 line-height-12" style="height:140px;">
          <br><br>
          <span v-if="order.pay_status > 0 && !order.delivery_status" class="white"><i class="iconfont my-delivery size64" /><br>待发货</span>
          <span v-if="order.pay_status > 0 && order.delivery_status && !order.receipt_status" class="white"><i class="iconfont my-receipt size64" /><br>待收货</span>
          <!-- <span v-if="order.pay_status > 0 && order.delivery_status && order.receipt_status && !order.is_comment" class="white"><i class="iconfont my-reply size64" /><br>待评价</span>
          <span v-if="order.pay_status > 0 && order.delivery_status && order.receipt_status && order.is_comment" class="green"><i class="iconfont my-ok size64 green" /><br>已完成</span> -->
          <span v-if="order.pay_status > 0 && order.delivery_status && order.receipt_status" class="green"><i class="iconfont my-ok size64 green" /><br>已完成</span>
          <span v-if="order.pay_status < 0" class="color-99"><i class="iconfont my-fail size64 color-99" /><br>{{ order.pay_status === -1 ? '支付失败' : (order.pay_status === -2 ? '已取消' : (order.pay_status === -3 ? '已过期' : '')) }}</span>
          <span v-if="order.pay_status === 0" class="yellow"><i class="iconfont my-pay size64 yellow" /><br>待付款</span>
        </div>
        <div v-if="order.brick_orderid && order.diy && !order.isGoods" class="block-round margin-bottom8" style="height:120px;">
          <div class="float-left" style="height:100px;width: 100px;">
            <el-image v-if="order.image" fit="scale-down" :src="getOrderImage(order)" class="round4" style="height:100px;width: 100px;" />
            <el-image v-if="!order.image && order.workid" fit="scale-down" :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + order.workid + '/' + order.workid + '.png?ver=' + order.update_at" class="round4" style="height:120px;width: 120px;" />
          </div>
          <div class="float-left padding-top12 margin-left12 line-height-20" style="width: calc(100% - 140px)">
            <el-row class="ellipsis white ellipsis">
              {{ order.name }}
            </el-row>
            <el-row>
              <span class="color-99">{{ order.info }}</span><br>
              {{ 'x' + order.num }}
            </el-row>
          </div>
        </div>
        <div v-if="order.brick_orderid && order.suites">
          <div v-for="(ord, index) in order.suites" :key="index" class="block-round margin-bottom8" style="height:120px;">
            <div class="float-left" style="height:100px;width: 100px;">
              <el-image v-if="ord.image" fit="scale-down" :src="cdnHost + ord.image + '?v=1.063'" class="round4" style="height:100px;width: 100px;" />
              <el-image v-if="!ord.image && ord.goods_image" fit="scale-down" :src="ord.goods_image" class="round4" style="height:100px;width: 100px;" />
            </div>
            <div class="float-left padding-top12 margin-left12 line-height-20" style="width: calc(100% - 120px)">
              <el-row class="ellipsis white ellipsis">
                {{ ord.name }}
              </el-row>
              <el-row>
                <span class="color-99">{{ ord.info }}</span><br>
                {{ 'x' + ord.num }}
              </el-row>
            </div>
          </div>
        </div>
        <div class="block-round line-height-20" :style="{'margin-bottom': isDesktop ? '55px' : '90px'}">
          <el-row>
            <el-col :span="8" class="color-99">订单金额</el-col>
            <el-col :span="16" class="align-right">
              ¥{{ formateMoney(order.amount) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">交易数量</el-col>
            <el-col :span="16" class="align-right">
              {{ getTradeNums(order) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">创建时间</el-col>
            <el-col :span="16" class="align-right">
              {{ getDate(order.add_at) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">订单编号</el-col>
            <el-col :span="16" class="align-right">
              <div class="clipboard" :data-clipboard-text="'B' + order.order_sn" @click="clipboardCopy('order_sn')">
                B{{ order.order_sn }} <i class="iconfont my-copy size16 color-bb" />
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">支付方式</el-col>
            <el-col :span="16" class="align-right">
              {{ order.pay_type === 0 ? '晶币支付' : (order.pay_type === 1 ? '晶币支付' : (order.pay_type === 2 ? '微信支付' : (order.pay_type === 12 ? '支付宝支付' : '其他'))) }}
            </el-col>
          </el-row>
          <el-row v-if="order.pay_status">
            <el-col :span="8" class="color-99">付款时间</el-col>
            <el-col :span="16" class="align-right">
              {{ order.pay_at ? getDate(order.pay_at) : '-' }}
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="config.type === 'brickorder'" class="footer padding-top8" :style="{'position': isDesktop ? 'absolute' : ''}">
        <el-button v-if="order.pay_status === 0" type="primary" class="btn-cancel" style="max-width:200px;" @click="cancelIt(order)">
          取消订单
        </el-button>
        <el-button v-if="order.pay_status === 0" type="light" class="btn-ok" style="max-width:200px;" @click="payIt(order)">
          立即支付
        </el-button>
        <el-button v-if="order.pay_status > 0 && order.delivery_status && !order.receipt_status" type="light" class="btn-ok" style="max-width:200px;" @click="receiptIt(order)">
          确认收货
        </el-button>
        <el-button v-if="order.pay_status > 0" type="light" class="btn-ok" style="max-width:200px;" @click="openPaperViewer()">
          获取图纸
        </el-button>
      </div>
      <div v-if="config.type === 'toBrickfy' && step !== 1" class="align-center padding-top24 padding-bottom24">
        <!-- <el-row class="padding-top12">
          <img src="@/assets/logo-s.png" title="Gridy.Art 百格画">
        </el-row>
        <el-row class="padding8">
          <span class="opacity-bg color-cc padding8 round4 size12">上传照片 一键生成拼图/像素头像</span>
        </el-row> -->
        <el-row class="padding4">
          <div class="padding8">
            <div class="padding-bottom12">
              <el-radio-group v-model="colorfyId" @input="() => emit('traceEvent', ['changeColorfyId', '', { colorfyId: colorfyId }])">
                <el-radio-button v-for="(item, k) in colorfyDt" v-show="k !=='colors'" :key="k" :label="k" class="opacity-bg">{{ item.name }}</el-radio-button>
              </el-radio-group>
            </div>
            <div :style="carouselStyle">
              <div class="twenty-images">
                <TwentyTwenty :offset="offset" :before="cdnHost + 'images/guide/origin.jpg?v=1.063'" :after="'images/guide/3_' + (colorfyId || 'dance') + '.jpg?v=1.063'" />
              </div>
            </div>
          </div>
        </el-row>
        <el-row class="padding12 margin-bottom16">
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">快上传照片，看看您的像素拼图是什么样子的吧。</div>
            <el-button type="light" style="width: 155px;" @click="startNow">开始</el-button>
          </div>
          <!-- <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">这里可以免费制作自己的像素画、像素头像。</div>
            <el-button type="yellow" style="width: 155px;" @click="gotoEditer">在线创作</el-button>
          </div>
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">已经购买了吗？请输入系列号获取图纸吧。</div>
            <el-button type="green" style="width: 155px;" @click="reqSN">获取图纸</el-button>
          </div> -->
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">还没拥有个性定制的像素拼图吗？</div>
            <el-button type="yellow" style="width: 155px;" @click="gotoStore()">我要购买</el-button>
          </div>
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">大额优惠券等着你哦，快来领取把。</div>
            <el-button type="green" style="width: 155px;" @click="openSelecter({ 'title': '领取优惠券', 'table': 'coupon', 'keyfield': 'coupon_id', 'maxSelectCount': 1, 'simple': true }, true)">领取优惠券</el-button>
          </div>
        </el-row>
      </div>
      <div v-if="config.type === 'toBrickfy' && step === 1" class="footer padding-top8" :style="{'position': isDesktop ? 'absolute' : ''}">
        <el-button type="primary" class="btn-cancel" style="max-width:200px;" @click="step = 0">
          返回
        </el-button>
        <el-button type="light" class="btn-ok" style="max-width:200px;" @click="openLocalImages('brick', colorfyId, cols, rows)">
          上传照片
        </el-button>
      </div>
      <div v-if="config.type === 'toBrickfy' && step === 1" class="align-center padding-top12 padding-bottom24">
        <el-row v-if="!isDesktop" style="line-height: 60px;">
          快上传一张清晰的个人照片，看看效果吧
        </el-row>
        <el-row style="margin:0 auto;" :style="{'width': isDesktop ? '80%' : 'min(90vh,90vw)'}">
          <el-col :span="12" class="padding12">
            <img :src="cdnHost + 'images/guide/0.jpg?v=1.063'" width="100%" class="round8">
          </el-col>
          <el-col :span="12" class="padding12">
            <img :src="cdnHost + 'images/guide/1_' + colorfyId + '.jpg?v=1.063'" width="100%" class="round8">
          </el-col>
        </el-row>
        <el-row style="margin:0 auto;" :style="{'width': isDesktop ? '80%' : 'min(90vh,90vw)'}">
          <el-col :span="12" class="padding12">
            <img :src="cdnHost + 'images/guide/2.jpg?v=1.063'" width="100%" class="round8">
          </el-col>
          <el-col :span="12" class="padding12">
            <img :src="cdnHost + 'images/guide/3_' + colorfyId + '.jpg?v=1.063'" width="100%" class="round8">
          </el-col>
        </el-row>
        <el-row style="height: 45px;">
          小贴士：脸部占比大一点，效果会更好
        </el-row>
      </div>
      <div v-if="config.type === 'toPixel'" class="align-center padding-top24 padding-bottom12">
        <el-row class="padding-top24">
          <img src="@/assets/logo-s.png" title="Gridy.Art 百格画">
        </el-row>
        <el-row class="padding12">
          <span class="opacity-bg color-cc padding8 round4 size12">上传图片 一键生成像素画/像素头像</span>
        </el-row>
        <el-row>
          <div class="padding8">
            <div :style="carouselStyle">
              <el-carousel trigger="click" indicator-position="none" :height="carouselHeight + 'px'">
                <el-carousel-item v-for="img in pixelImages" :key="img">
                  <img :src="img" :style="{'width': carouselHeight + 'px', 'height': carouselHeight + 'px'}" class="round8">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-row>
        <el-row class="padding12" style="margin-bottom: 56px;">
          <el-button type="light" style="width: 155px;" @click="openLocalImages()">上传图片</el-button>
          <el-button type="yellow" style="width: 155px;" @click="gotoEditer()">创作像素头像</el-button>
        </el-row>
      </div>
      <div v-if="config.type === 'toCartoon'" class="align-center padding-top24 padding-bottom12">
        <el-row class="padding-top24">
          <img src="@/assets/logo-s.png" title="Gridy.Art 百格画">
        </el-row>
        <el-row class="padding12">
          <span class="opacity-bg color-cc padding8 round4 size12">上传照片 一键生成动漫形象</span>
        </el-row>
        <el-row>
          <div class="padding8">
            <div :style="carouselStyle">
              <el-carousel trigger="click" indicator-position="none" :height="carouselHeight + 'px'">
                <el-carousel-item v-for="img in cartoonImages" :key="img">
                  <img :src="img" :style="{'width': carouselHeight + 'px', 'height': carouselHeight + 'px'}" class="round8">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-row>
        <el-row class="padding12" style="margin-bottom: 56px;">
          <el-dropdown split-button type="light" trigger="click" class="dropdown-button" @click="createCartoon" @command="(e) => paint.cartoonStyleid = e">
            上传照片
            <el-dropdown-menu slot="dropdown" class="my-dropdown">
              <el-dropdown-item v-for="(item, i) in cartoonStyles" :key="i" :command="i">
                {{ item[1] }}
                <span v-if="paint.cartoonStyleid - i === 0" class="float-right align-right iconfont my-check" />
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="yellow" style="width: 155px;margin-left: 8px;" @click="gotoEditer()">创作像素画</el-button>
        </el-row>
      </div>
      <div v-if="config.type === 'toBrickArt'" class="align-center padding-top24 padding-bottom12">
        <el-row class="padding-top24">
          &nbsp;
        </el-row>
        <el-row>
          <div class="padding8">
            <div :style="carouselStyle">
              <el-carousel trigger="click" indicator-position="none" :height="carouselHeight + 'px'">
                <el-carousel-item v-for="img in brickartImages" :key="img">
                  <img :src="img" :style="{'width': carouselHeight + 'px', 'height': carouselHeight + 'px'}" class="round8">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-row>
        <el-row class="padding12" style="margin-bottom: 56px;">
          <div class="align-left" style="width: 300px;margin: 0 auto;">
            <div class="line-height-25">已经购买了吗？请输入系列号获取图纸吧。</div>
            <el-button type="green" style="width: 155px;" @click="reqSN">获取图纸</el-button>
          </div>
          <div class="align-left" style="width: 300px;margin: 0 auto;">
            <div class="line-height-25">还没拥有百变拼图吗？</div>
            <el-button type="yellow" style="width: 155px;" @click="gotoStore()">我要购买</el-button>
          </div>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import 'vue-twentytwenty/dist/vue-twentytwenty.css'
import TwentyTwenty from 'vue-twentytwenty'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import GRIDY from '@/js/sdk/GridySDK'
import conf from '@/js/conf/conf'
import service from '@/js/service'
export default {
  components: {
    TwentyTwenty
  },
  mixins: [mixins],
  props: {
    feedback: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    const form = {
      phone: '',
      code: '',
      password: '',
      repassword: '',
      username: '',
      nickname: '',
      sex: 1,
      checked: 0,
      realname: '',
      idcard: ''
    }
    const resolutions = [['768:768', '方图', 'my-ratio-1-1'], ['768:1024', '竖图', 'my-ratio-3-4'], ['1024:768', '横图', 'my-ratio-4-3']]
    const stylesConf = [
      ['000', '不限定风格', 'cos://000/01/00/14/10208.png'],
      ['201', '动漫风格', 'cos://000/01/00/14/10200.png'],
      ['301', '游戏卡通', 'cos://000/01/00/14/10199.png'],
      ['114', '科幻风格', 'cos://000/01/00/14/10188.png'],
      ['113', '赛博朋克', 'cos://000/01/00/14/10138.png'],
      ['102', '概念艺术', 'cos://000/01/00/14/10180.png'],
      ['115', '黑暗艺术', 'cos://000/01/00/14/10166.png'],
      ['112', '黑白素描', 'cos://000/01/00/14/10167.png'],
      ['110', '2.5D人像', 'cos://000/01/00/14/10169.png'],
      ['111', '肖像画', 'cos://000/01/00/14/10201.png'],
      ['109', '印象派', 'cos://000/01/00/14/10176.png'],
      ['107', '插图', 'cos://000/01/00/14/10207.png']
    ]
    const styles = []
    for (const i in stylesConf) {
      styles.push([stylesConf[i][0], stylesConf[i][1], service.getCosUrl(stylesConf[i][2], 'paint')])
    }
    const cartoonStylesConf = [
      ['000', '不限定风格', 'cos://000/01/00/14/10208.png'],
      ['201', '日系动漫', 'cos://000/01/00/14/10138.png'],
      ['202', '美系动漫', 'cos://000/01/00/14/10199.png'],
      ['203', '唯美古风', 'cos://000/01/00/14/10200.png'],
      ['106', '水彩画', 'cos://000/01/00/14/10188.png']
    ]
    const cartoonStyles = []
    for (const i in cartoonStylesConf) {
      cartoonStyles.push([cartoonStylesConf[i][0], cartoonStylesConf[i][1], service.getCosUrl(cartoonStylesConf[i][2], 'paint')])
    }
    const paint = {
      total: 120,
      largeMod: false,
      thumbFactor: this.state.platform.type === 'desktop' ? 1 : 0.75,
      placeholder: '请输入或选择效果关键词',
      tags: ['青春', '可爱', '英俊', '少年', '长发', '城市', '乡村', '吊带', '女生', '机甲', '呆萌', '校服', '废墟', '草地', '太阳镜', '棒球帽', '大眼睛', '戴帽子', '短发', '唯美', '马尾辫', '科幻', '星际', '萝莉', '毛茸茸', '布偶', '武侠风', '细节丰富', '照片质感', '自然风光', '面容精致', '高对比度', '天空晴朗', '瀑布河流', '风景如画', '乡间小路', '电影效果'],
      content: '',
      file: null,
      largeInput: false,
      resolutionid: 0,
      resolution: '768:768',
      styleid: 0,
      cartoonStyleid: 0
    }
    const brickfyImages = [service.getCosUrl('cos://brick_03.png', 'assets'), service.getCosUrl('cos://brick_02.png', 'assets'), service.getCosUrl('cos://brick_01.png', 'assets')]
    const pixelImages = [service.getCosUrl('cos://pixel_01.png', 'assets'), service.getCosUrl('cos://pixel_02.png', 'assets'), service.getCosUrl('cos://pixel_03.png', 'assets')]
    const brickartImages = [service.getCosUrl('cos://brickart_001.png', 'assets'), service.getCosUrl('cos://brickart_002.png', 'assets'), service.getCosUrl('cos://brickart_003.png', 'assets'), service.getCosUrl('cos://brickart_004.png', 'assets'), service.getCosUrl('cos://brickart_005.png', 'assets')]
    const cartoonImages = [service.getCosUrl('cos://carton_02.jpg', 'assets'), service.getCosUrl('cos://carton_03.jpg', 'assets'), service.getCosUrl('cos://carton_01.jpg', 'assets')]
    const rules = {
      phone: [{ trigger: 'blur', validator: this.validPhone }],
      code: [{ trigger: 'blur', validator: this.validCode }],
      username: [{ trigger: 'blur', validator: this.validUsername }],
      password: [{ trigger: 'blur', validator: this.validPassword }],
      repassword: [{ trigger: 'blur', validator: this.validRepassword }],
      nickname: [{ trigger: 'blur', validator: this.validNickname }],
      realname: [{ trigger: 'blur', validator: this.validRealname }],
      idcard: [{ trigger: 'blur', validator: this.validIdcard }],
      checked: [{ trigger: 'blur', validator: this.validChecked }]
    }
    let container_width
    if (this.state.platform.type === 'desktop') {
      container_width = 632
    } else {
      container_width = utils.width()
    }
    const data = {}
    data.GRIDY = null
    data.brickfyImages = brickfyImages
    data.pixelImages = pixelImages
    data.brickartImages = brickartImages
    data.cartoonImages = cartoonImages
    data.resolutions = resolutions
    data.styles = styles
    data.cartoonStyles = cartoonStyles
    data.paint = paint
    data.imageSize = 120
    data.maxImageWidth = container_width * 0.9
    data.orderTypes = conf.orderTypes
    data.worksHost = conf.hosts().worksHost
    data.platform = utils.platform()
    data.apiHost = conf.hosts().apiHost
    data.mainHost = conf.hosts().mainHost
    data.cdnHost = conf.hosts().cdnHost
    data.downloadUrl = conf.hosts().downloadUrl
    data.storeHost = conf.hosts().storeHost
    data.ver = conf.ver
    data.settings = {
      agreement: '',
      privacy: ''
    }
    data.step = 0
    data.publish = {}
    data.order = {}
    data.publishInfo = ''
    data.form = form
    data.rules = rules
    data.countDownNum = 0
    data.countDownIt = null
    data.colorfyDt = utils.deepClone(conf.colorfyDt)
    data.colorfyId = 'dance'
    data.cols = 0
    data.rows = 0
    data.offset = 0.4
    // defaultSkuId 10002=钻石拼图 10001=积木拼图
    data.defaultSkuId = ''
    return data
  },
  computed: {
    reqId() {
      return this.state.reqId
    },
    config() {
      return this.view.popPage
    },
    powers() {
      return this.state.powers || {}
    },
    series() {
      return this.state.series || {}
    },
    publishes() {
      return this.state.publishes || {}
    },
    publishid() {
      return this.view.popPage.publishid
    },
    orderid() {
      return this.view.popPage.orderid
    },
    skuId() {
      return this.view.popPage.skuId || this.defaultSkuId
    },
    title() {
      let title = this.config.title
      if (!title && this.config.type) {
        for (const k in this.config.types) {
          if (this.config.type === this.config.types[k][0]) {
            title = this.config.types[k][1]
            break
          }
        }
      }
      return title
    },
    txtCount() {
      return (this.paint.total * 2 - utils.strLength(this.paint.content)) + '/' + this.paint.total * 2
    },
    carouselHeight() {
      return 320
    },
    carouselStyle() {
      const style = {}
      style.margin = '0 auto'
      style.width = this.carouselHeight + 'px'
      style.height = this.carouselHeight + 'px'
      return style
    },
    containerStyle() {
      const style = {
        display: 'inline-table',
        width: '100%',
        'background-size': 'cover'
      }
      let img = ''
      let pos = ''
      if (this.config.type === 'toBrickfy') {
        pos = 'top'
        img = 'sky0/ft.jpg'
      } else if (this.config.type === 'toPixel') {
        pos = 'center top'
        img = 'images/sky_bg.jpg'
      } else if (this.config.type === 'toCartoon' || this.config.type === 'toBrickArt') {
        pos = 'left top'
        img = 'images/sky_bg.jpg'
      }
      if (img) style['background-image'] = 'url(' + this.cdnHost + img + ')'
      if (pos) style['background-position'] = pos
      return style
    },
    source() {
      return this.state.source
    },
    paintPrice() {
      return conf.paintConsume[this.state.userGroupId] || 90
    },
    canPaint() {
      const quantity = (this.source[10007] && this.source[10007].quantity) ? this.source[10007].quantity : 0
      return quantity >= this.paintPrice
    }
  },
  watch: {
    'paint.content': {
      handler() {
        if (utils.strLength(this.paint.content) > this.paint.total) {
          this.paint.content = utils.getString(this.paint.content, this.paint.total)
        }
      }
    }
  //   'view.popPage.show': {
  //     handler() {
  //       // if (this.$refs['formWidget']) this.$refs['formWidget'].resetFields()
  //       if (this.view.popPage.show) {
  //         if (this.view.popPage.type === 'agreement' || this.view.popPage.type === 'privacy' || this.view.popPage.type === 'nftnotice') {
  //           this.getSettings(this.view.popPage.type)
  //           if (this.view.popPage.type === 'nftnotice') this.getPublish()
  //         } else if (this.view.popPage.type === 'nftorder') {
  //           this.getOrder(this.view.popPage.type)
  //         } else if (this.view.popPage.type === 'publishInfo' || this.view.popPage.type === 'publishCert') {
  //           this.getPublish()
  //         } else if (this.view.popPage.type === 'publishPower') {
  //           this.getSettings('powernotice')
  //           this.getPublishPower()
  //         }
  //         this.update()
  //       }
  //     }
  //   }
  },
  mounted() {
    this.step = 0
    if (this.view.popPage.type === 'agreement' || this.view.popPage.type === 'privacy' || this.view.popPage.type === 'nftnotice') {
      this.getSettings(this.view.popPage.type)
      if (this.view.popPage.type === 'nftnotice') this.getPublish()
    } else if (this.view.popPage.type === 'nftorder' || this.view.popPage.type === 'brickorder') {
      this.getOrder(this.view.popPage.type)
    } else if (this.view.popPage.type === 'publishInfo' || this.view.popPage.type === 'publishCert') {
      this.getPublish()
    } else if (this.view.popPage.type === 'publishPower') {
      this.getSettings('powernotice')
      this.getPublishPower()
    } else if (this.view.popPage.type === 'toBrickfy') {
      // URL 传递重要参数示例：/#/?mod=popPage&type=toBrickfy&inviteid=1&cols=[48]&rows=[48]&skuId=[10001|10002]&referrer=[sn]&colorfy=[dance|classic|golden]&reqLogin=true&snid=1&sn=9012312321
      // referrer=sn 表示扫系列号进入该页面，cols、rows 可以指定像素画长度和高度，colorfy可指定默认配色, skuId 指定是10001积木画还是10002钻石画, reqLogin 指定是否要求登录（未实现），sn为系列号
      const params = utils.getHashParams()
      this.colorfyId = params.colorfy || 'dance'
      this.cols = params.cols || 0
      this.rows = params.rows || 0
      // 自动绑定激活系列号
      if (params.sn && this.loginUserId) {
        this.emit('activeSN', [params.sn])
      }
    } else if (this.view.popPage.type === 'paint') {
      if (!this.loginUserId) this.emit('login')
      if (this.view.popPage.data) {
        setTimeout(() => {
          this.initPaint(this.view.popPage.data.paint)
        }, 100)
      }
      this.getSource()
    }
  },
  methods: {
    reqSN() {
      const next = (status, dataSN) => {
        this.colorfyId = dataSN.colorfy || 'dance'
        this.cols = dataSN.cols || 0
        this.rows = dataSN.rows || 0
        this.step = 1
        if (this.config.type === 'toBrickArt') {
          if (!dataSN.albumid && !dataSN.workid) {
            // service.removeSN()
            this.emit('reqSN', [0, '', '', next])
          } else if (dataSN.workid) {
            const data = {
              userid: this.loginUserId,
              workid: dataSN.workid,
              publishid: 0
            }
            this.emit('openBrickyViewer', [{ data: data }])
          } else if (dataSN.albumid) {
            this.goto('albumWorks', { albumid: dataSN.albumid, typeid: 1 })
          }
        }
      }
      this.emit('reqSN', [0, '', '', next])
    },
    getOrderImage(item) {
      if (!item || !item.image) return ''
      if (item.image.substr(0, 6) === 'cos://') {
        item.image = item.image.replace('public/order/', '')
        return service.getCosUrl(item.image, 'order', '') || ''
      } else {
        return item.image
      }
    },
    // 获取晶钻
    getSource() {
      this.emit('getSource', [(dt, type) => {
        this.update()
      }, 'coin'])
    },
    gotoEditer() {
      this.emit('traceEvent', ['popPage_gotoEditer', '', { type: this.config.type }])
      // const search = utils.param2Obj(window.location.search)
      // if (!this.loginStatus && search.td_channelid && search.td_channelid.indexOf('baidu') >= 0) {
      //   this.message('请先登录，再开始创作像素画！', 'login')
      //   return
      // }
      // if (!this.loginStatus) {
      //   this.message('请先登录，再生成拼图！', 'login')
      //   return
      // }
      this.close()
      this.goto('editer')
    },
    gotoWorks(classid = 1) {
      this.emit('traceEvent', ['popPage_gotoWorks', '', { type: this.config.type }])
      this.close()
      this.emit('gotoWorks', [classid])
    },
    // 下单
    orderGoods(goods) {
      this.emit('traceEvent', ['orderGoods', '', {}])
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      // 构造数据
      const suites = []
      if (utils.isArray(goods)) {
        for (const i in goods) {
          goods[i].addCart = true
          goods[i].isGoods = true
          goods[i].workid = 0
          goods[i].id = goods[i].goods_id
          goods[i].goodsId = goods[i].goods_id
          delete goods[i].goods_id
          const goods10001 = utils.deepClone(goods[i])
          if (goods10001.cart['10001']) {
            goods10001.num = goods10001.cart['10001']
            goods10001.goodsSkuId = '10001'
            goods10001.price = goods10001.goods_price_max * 100
            goods10001.info = goods10001.cols + 'x' + goods10001.rows + '积木拼图'
            delete goods10001.cart
            suites.push(goods10001)
          }
          const goods10002 = utils.deepClone(goods[i])
          if (goods10002.cart['10002']) {
            goods10002.num = goods10002.cart['10002']
            goods10002.goodsSkuId = '10002'
            goods10002.price = goods10002.goods_price_max * 100
            goods10002.info = goods10002.cols + 'x' + goods10002.rows + '积木拼图'
            delete goods10002.cart
            suites.push(goods10002)
          }
        }
      }
      this.emit('openOrderMng', [{ act: 'create', data: suites }])
      // this.emit('openOrderMng', [{ act: 'create', data: [{ isGoods: true, goods: goods, isSuite: false, type: '', bgId: '', colorfyId: '', frameId: '', hideColors: true, work: '', imageUrl: '', name: '' }] }])
    },
    // 开始
    startNow() {
      this.step = 1
      // this.alert('脸部占比大一点，效果会更好')
    },
    // 购买商品
    gotoStore() {
      const config = { 'title': '商品列表', 'table': 'goods', 'keyfield': 'goods_id', 'maxSelectCount': 10, 'tab': '10001', 'tabs': [['10002', '钻石拼图'], ['10001', '积木拼图']], 'sift': '0', 'sifts': [['0', '全部'], ['1', '48x48'], ['2', '48x64'], ['3', '64x64']], 'simple': true }
      config.fn = this.orderGoods
      if (this.skuId + '' === '10001') {
        config.tab = '10001'
        config.tabs = [['10001', '积木拼图']]
      } else if (this.skuId + '' === '10002') {
        config.tab = '10002'
        config.tabs = [['10002', '钻石拼图']]
      }
      this.emit('openResourceSelecter', [config])
      // 去商城
      // window.openLink(this.storeHost)
    },
    openLocalImages(diyType = '', colorfyId = '', cols = 64, rows = 64) {
      this.emit('traceEvent', ['popPage_clickUpload', '', { type: this.config.type, diyType: diyType, colorfyId: colorfyId, skuId: this.skuId }])
      // const search = utils.param2Obj(window.location.search)
      // if (!this.loginStatus && search.td_channelid && search.td_channelid.indexOf('baidu') >= 0) {
      //   this.message('请先登录，再生成拼图！', 'login')
      //   return
      // }
      // if (!this.loginStatus) {
      //   this.message('请先登录，再生成' + (diyType === 'brick' ? '拼图' : '') + '！', 'login')
      //   return
      // }
      this.emit('openLocalImages', ['open', false, diyType, colorfyId, cols, rows, false, true, this.skuId])
    },
    createCartoon() {
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      this.state.openLocalImages.callback = (file) => {
        this.emit('loading', [true, 'AI作画中，请稍等...'])
        this.GRIDY = new GRIDY()
        const name = 'AI动漫_' + utils.getFileName(file.name)
        this.GRIDY.compressImage(name, window.URL.createObjectURL(file), (objData) => {
          let style = '000'
          if (this.cartoonStyles[this.paint.cartoonStyleid]) {
            style = this.cartoonStyles[this.paint.cartoonStyleid][0]
            this.state.paint.cartoonStyleid = this.paint.cartoonStyleid
          }
          const data = {
            name: name,
            info: name,
            // 来源；0=文生图; 1=图生图;
            origin: 1,
            resolution: '768:1024',
            input_image: objData.base64,
            style: style
          }
          service.post('paint', data, (dt, type) => {
            if (type === 'success') {
              if (dt.data.images) {
                this.emit('openResource', ['paint'])
                // this.emit('openGridyViewer', [{ data: { images: [service.getCosUrl(dt.data.images, 'paint', dt.data.id)], names: [name], idx: 0, type: 'imagesViewer' }}])
              }
              service.incCount(this.loginUserId, 'paint')
              if (dt.data.consumeNums) {
                setTimeout(() => {
                  this.alert('本次消费 ' + dt.data.consumeNums + ' 个晶钻，签到和做任务可以获得晶钻')
                }, 1000)
              }
            } else {
              if (type === 'login') {
                this.message(dt, type)
              } else {
                this.alert(dt)
              }
            }
            this.emit('loading', [false])
          }, true)
        }, 768, 768, 'base64')
      }
      this.emit('openLocalImages', ['paint'])
    },
    initPaint(conf = {}) {
      this.paint.content = conf.Prompt || ''
      if (conf.Styles) {
        for (const i in this.styles) {
          if (this.styles[i][0] === conf.Styles) {
            this.paint.styleid = i
            break
          }
        }
      } else if (this.state.paint.styleid) {
        this.paint.styleid = this.state.paint.styleid
      }
      this.paint.styleid = parseInt(this.paint.styleid)
      if (conf.Resolution) {
        for (const i in this.resolutions) {
          if (this.resolutions[i][0] === conf.Resolution) {
            this.paint.resolutionid = i
            break
          }
        }
      } else if (this.state.paint.resolutionid) {
        this.paint.resolutionid = this.state.paint.resolutionid
      }
      this.paint.resolutionid = parseInt(this.paint.resolutionid)
    },
    addPaintContent(tag) {
      this.paint.content = utils.trim(this.paint.content, true)
      this.paint.content = (this.paint.content ? this.paint.content + '，' : '') + tag
    },
    requestAiPaint() {
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      if (utils.getLen(this.paint.content) < 8) {
        this.alert('效果描述至少需要8个字')
        return
      }
      let style = '000'
      if (this.styles[this.paint.styleid]) {
        style = this.styles[this.paint.styleid][0]
        this.state.paint.styleid = this.paint.styleid
      }
      let resolution = '000'
      if (this.resolutions[this.paint.resolutionid]) {
        resolution = this.resolutions[this.paint.resolutionid][0]
        this.state.paint.resolutionid = this.paint.resolutionid
      }
      const data = {
        name: this.paint.content,
        info: this.paint.content,
        resolution: resolution,
        style: style
      }
      this.aiPaint(data, this.paint.content)
    },
    aiPaint(data, name) {
      this.emit('loading', [true, 'AI作画中，请稍等...'])
      service.post('paint', data, (dt, type) => {
        if (type === 'success') {
          if (dt.data.images) {
            this.emit('openGridyViewer', [{ data: { images: [service.getCosUrl(dt.data.images, 'paint', dt.data.id)], names: [name], idx: 0, type: 'imagesViewer' }}])
          }
          service.incCount(this.loginUserId, 'paint')
          if (dt.data.consumeNums) {
            setTimeout(() => {
              this.alert('本次消费 ' + dt.data.consumeNums + ' 个晶钻，签到和做任务可以获得晶钻')
            }, 1000)
          }
        } else {
          if (type === 'login') {
            this.message(dt, type)
          } else {
            this.alert(dt)
          }
        }
        this.emit('loading', [false])
      }, true)
    },
    validPhone(rule, value, cb) {
      if (!utils.checkNow('mobile', value, true)) {
        cb(new Error('请输入正确的手机号码'))
      } else {
        let checkMobile = value + ''
        checkMobile = checkMobile.slice(0, 3) + '****' + checkMobile.slice(-4)
        if (this.config.data.mobile && this.config.data.mobile !== checkMobile) {
          cb(new Error('请输入手机号 ' + this.config.data.mobile))
        } else {
          cb()
        }
      }
    },
    validCode(rule, value, cb) {
      if (!utils.checkNow('str-6-6', value, true)) {
        cb(new Error('请输入验证码'))
      } else {
        cb()
      }
    },
    validChecked(rule, value, cb) {
      if (!value) {
        cb(new Error('请阅读并同意《用户协议》和《隐私条款》'))
      } else {
        cb()
      }
    },
    validPassword(rule, value, cb) {
      if (this.config.type === 'setPayPassword') {
        if (!utils.checkNow('paypassword', value, true)) {
          cb(new Error('交易密码为6位数字'))
        } else {
          cb()
        }
      } else {
        if (!utils.checkNow('strongpassword', value, true)) {
          cb(new Error('8~20位字符，必须包含字母、数字和特殊字符'))
        } else {
          cb()
        }
      }
    },
    validRepassword(rule, value, cb) {
      if (!value) {
        cb(new Error('请输入确认密码'))
      } else if (value !== this.form.password) {
        cb(new Error('两次输入的密码不一致'))
      } else {
        cb()
      }
    },
    validUsername(rule, value, cb) {
      if (!utils.checkNow('username', value, true)) {
        cb(new Error('6~18位字符，包含字母、数字、下划线'))
      } else {
        cb()
      }
    },
    validNickname(rule, value, cb) {
      if (!utils.checkNow('nickname', value, true)) {
        cb(new Error('6~18位字符，包含字母、数字、下划线、汉字'))
      } else {
        cb()
      }
    },
    validRealname(rule, value, cb) {
      if (!utils.checkNow('str2-4-18', value, true)) {
        cb(new Error('请输入真实姓名'))
      } else {
        cb()
      }
    },
    validIdcard(rule, value, cb) {
      if (!utils.checkNow('idcard', value, true)) {
        cb(new Error('请输入正确的身份证号码'))
      } else {
        cb()
      }
    },
    // 粘贴板
    clipboardCopy(type) {
      let msg = ''
      if (type === 'order_sn') msg = '订单编号复制成功'
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        if (msg) this.message(msg, 'success')
        clipboard.destroy()
      })
    },
    handle(act, data) {
      this.emit(act, data)
    },
    getTradeNums(order) {
      let num = 0
      if (order.diy) {
        num = order.num
      }
      if (order.suites && order.suites.length) {
        for (const i in order.suites) {
          num = num + order.suites[i].num
        }
      }
      return num
    },
    getDate(time) {
      if (!time) return ''
      return utils.date('datetime', 0, new Date(time * 1000))
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    close() {
      const types = []
      for (const i in this.config.types) {
        types.push(this.config.types[i][0])
      }
      if (this.config.from && this.config.from.indexOf(types) >= 0) {
        this.config.type = this.config.from
        this.config.from = ''
        return
      }
      this.closePop(this.config)
      // if (this.config.fromUrl) this.goto()
    },
    // 发送反馈
    sendFeedback() {
      if (!utils.checkNow('str2-4-225', this.feedback.title, true)) return this.message('请输入反馈主题', 'error')
      if (!utils.checkNow('str2-4-2048', this.feedback.content, true)) return this.message('请输入反馈内容', 'error')
      if (!utils.checkNow('str2-4-20', this.feedback.name, true)) return this.message('请留下您的名字', 'error')
      if (!utils.checkNow('str2-4-100', this.feedback.contact, true)) return this.message('请留下联系方式', 'error')
      this.feedback.platform = this.platform.value
      this.feedback.ver = conf.ver
      this.config.loading = true
      const data = utils.deepClone(this.feedback)
      delete data.typeOpts
      service.post('feedback', data, (msg, type) => {
        if (type === 'success') {
          msg = msg.msg || '发送成功，感谢您的关注'
          this.feedback.title = ''
          this.feedback.content = ''
        }
        this.message(msg, type)
        this.config.loading = false
      })
    },
    calcWorkSize(w, h) {
      if (w < this.imageSize && h < this.imageSize) return { width: w, height: h }
      let width, height
      if (w > h) {
        width = this.imageSize
        height = Math.round(width * h / w)
      } else {
        height = this.imageSize
        width = Math.round(height * w / h)
      }
      return { width: width, height: height }
    },
    imgStyle(work) {
      if (!work) return {}
      const size = this.calcWorkSize(work.width, work.height)
      const left = (this.imageSize - size.width) / 2
      const top = (this.imageSize - size.height) / 2
      return { 'padding-left': left + 'px', 'padding-top': top + 'px' }
    },
    // 领取优惠券
    receiveCoupon(coupon_id) {
      if (!coupon_id) return
      service.receiveCoupon(coupon_id, (dt, type) => {
        if (type === 'success') {
          this.alert('领取成功', type)
        } else {
          if (dt) this.alert(dt, type)
        }
      })
    },
    // 打开选择器
    openSelecter(config, reqLogin = false) {
      if (reqLogin && !this.loginUserId) return this.message('', 'login')
      config.fn = (items) => {
        if (config.table === 'coupon') {
          if (items && items[0] && items[0].coupon_id) {
            this.receiveCoupon(items[0].coupon_id)
          }
        }
      }
      this.emit('openResourceSelecter', [config])
    },
    // 取消订单
    cancelIt(order) {
      this.confirm('确定取消订单吗', (e) => {
        if (e === 'cancel') return
        let resource = ''
        let orderid = 0
        if (this.config.type === 'nftorder') {
          resource = 'nft_order'
          orderid = order.nft_orderid
        } else if (this.config.type === 'brickorder') {
          resource = 'brick_order'
          orderid = order.brick_orderid
        }
        service.put(resource, orderid, { type: 'cancel' }, (dt, type) => {
          if (type === 'success' && dt && dt.data && dt.data.rows) {
            this.message('订单已取消', type)
            this.order.pay_status = -2
            this.update()
            this.view.nftorder.renew = utils.time('time')
            this.view.order.renew = utils.time('time')
            setTimeout(this.close, 100)
          } else {
            this.message(dt, type)
          }
        }, true)
      })
    },
    // 订单支付
    payIt(order) {
      const pay_cb = (status) => {
        if (status) {
          this.order.pay_status = 1
          this.update()
        }
      }
      if (this.config.type === 'nftorder') {
        if ((order.type === 1 && order.preorder_status === 1) || order.type === 2 || order.type === 3) {
          this.showPayment(order, pay_cb)
        }
      } else {
        this.showPayment(order, pay_cb)
      }
    },
    // 确认收货
    receiptIt(order) {
      service.receipt(order.order_id, (dt, type) => {
        if (type === 'success') {
          order.receipt_status = 1
          this.alert('已确认收货')
          this.update()
        } else {
          this.message(dt, type)
        }
      })
    },
    openPaperViewer() {
      this.getOrder('brickorder', (order) => {
        if (order && order.data && order.data.work) {
          this.emit('openPaperViewer', [{ data: { colorfyId: order.data.colorfyId, frameId: order.data.frameId, hideColors: order.data.hideColors, work: order.data.work, imageUrl: order.image, name: order.data.work.name }}])
          setTimeout(this.close, 2000)
        }
      }, 0)
    },
    showPayment(order, cb) {
      this.view.pop.title = '支付'
      this.view.pop.type = this.config.type === 'nftorder' ? 'payment_nft' : 'payment_brick'
      this.view.pop.data = order || {}
      this.view.pop.show = true
      this.view.pop.fn = cb
    },
    getOrder(type, cb, simple = 1) {
      if (type === 'nftorder') {
        service.get('nft_order', this.orderid, (dt, type) => {
          if (type === 'success' && dt.count) {
            this.order = dt.items[0]
            if (this.order.publishid && dt.publishes) this.order.publish = dt.publishes[this.order.publishid] || {}
            if (this.order.workid && dt.works) this.order.work = dt.works[this.order.workid] || {}
            if (this.order.publish && this.order.publish.userid && dt.users) this.order.user = dt.users[this.order.publish.userid] || {}
          }
          this.update()
        }, true, '', false)
      } else if (type === 'brickorder') {
        service.get('brick_order', this.orderid, (dt, type) => {
          if (type === 'success' && dt && dt.data && dt.data.items) {
            if (cb) {
              cb(dt.data.items[0])
            } else {
              this.order = dt.data.items[0]
            }
          }
          this.update()
        }, true, { simple: simple }, false)
      }
    },
    // 获取配置信息
    getSettings(tag) {
      service.get('settings', tag, (dt, type) => {
        if (type === 'success') {
          this.settings[tag] = dt.data.value || ''
          this.update()
        }
      }, false, '', true)
    },
    // 获取藏品信息
    getPublish(cb) {
      this.publish = {}
      if (this.publishes[this.publishid]) {
        this.publish = this.publishes[this.publishid]
        this.update()
        cb && cb(this.publish)
        return
      }
      service.get('publish', this.publishid, (dt, type) => {
        if (type === 'success') {
          this.publish = dt.data
        }
        this.update()
        cb && cb(this.publish)
      }, false, '', false)
    },
    // 获取藏品权益
    getPublishPower() {
      this.getPublish((publish) => {
        if (publish && Array.isArray(publish.powers)) {
          const ids = []
          for (var idx in publish.powers) {
            if (!this.state.powers[publish.powers[idx]]) ids.push(publish.powers[idx])
          }
          if (!ids.length) return
          service.batchGet('power', ids.join(','), (dt, type) => {
            if (type === 'success') {
              if (dt.data && dt.data.items) {
                for (var i in dt.data.items) {
                  this.state.powers[dt.data.items[i].powerid] = dt.data.items[i]
                }
              }
            }
            this.update()
          }, false, '', false)
        }
      })
    },
    // 检查更新
    checkUpdate() {
      this.emit('checkUpdate')
    },
    // type 验证类别；0=注册; 1=登录; 2=实名认证; 3=二次认证;
    sendSMS() {
      this.validPhone('', this.form.phone, (err) => {
        if (err) {
          this.message(err.message, 'error')
        } else {
          if (this.config.type === 'setPassword') return this.sendDzqSMS('reset_pwd')
          if (this.config.type === 'setPayPassword') return this.sendDzqSMS('reset_pay_pwd')
          if (this.config.type === 'unbindPhone') return this.sendDzqSMS('verify')
          if (this.config.type === 'bindPhone') return this.sendDzqSMS('rebind')
          let type = 3
          if (this.config.type === 'realVerify') type = 2
          this.emit('sendSMS', [type, this.form.phone])
        }
      })
    },
    // 发送DZQ验证码
    sendDzqSMS(type) {
      this.validPhone('', this.form.phone, (err) => {
        if (err) {
          this.message(err.message, 'error')
        } else {
          this.emit('sendDzqSMS', [type, this.form.phone])
        }
      })
    },
    // 分享、邀请
    share() {
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message('链接复制成功，请粘贴邀请好友吧', 'success')
        clipboard.destroy()
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.config.type === 'setPassword') {
            service.setPassword(this.form.phone, this.form.code, this.form.password, (dt, type) => {
              if (type === 'success') {
                this.alert('登录密码已修改，请重新登录')
                this.form.code = ''
                this.form.password = ''
                this.form.repassword = ''
                this.closePop(this.config)
                this.emit('logout', [true])
                this.goto('index')
                this.emit('login')
              } else {
                this.message(dt, type)
              }
            })
          } else if (this.config.type === 'setPayPassword') {
            service.setPayPassword(this.form.phone, this.form.code, this.form.password, this.form.repassword, (dt, type) => {
              if (type === 'success') {
                this.message('交易密码已修改', type)
                this.form.code = ''
                this.form.password = ''
                this.form.repassword = ''
                this.view.account.renew = utils.time('time')
                this.closePop(this.config)
              } else {
                this.message(dt, type)
              }
            })
          } else if (this.config.type === 'unbindPhone') {
            service.smsVerify(this.form.phone, this.form.code, (dt, type) => {
              if (type === 'success') {
                this.config.type = 'bindPhone'
                this.config.data.mobile = ''
                this.form.phone = ''
                this.form.code = ''
                this.state.countDownNum = 0
                if (this.state.countDownIt) {
                  clearInterval(this.state.countDownIt)
                  this.state.countDownIt = null
                }
              } else {
                this.message(dt, type)
              }
            })
          } else if (this.config.type === 'bindPhone') {
            service.smsRebind(this.form.phone, this.form.code, (dt, type) => {
              if (type === 'success') {
                this.message('绑定成功', type)
                this.form.phone = ''
                this.form.code = ''
                this.view.account.renew = utils.time('time')
                this.closePop(this.config)
              } else {
                this.message(dt, type)
              }
            })
          } else if (this.config.type === 'realVerify') {
            const params = {}
            params.type = 'verify'
            params.phone = this.form.phone
            params.code = this.form.code
            params.realname = this.form.realname
            params.identity = this.form.idcard
            service.actionPut('user', params, (dt, type) => {
              if (type === 'success') {
                this.message('实名信息提交成功', type)
                this.form.phone = ''
                this.form.code = ''
                this.view.account.renew = utils.time('time')
                this.closePop(this.config)
              } else {
                this.message(dt, type)
              }
            }, true)
          }
        }
      })
    }
  }
}
</script>
