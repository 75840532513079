<template>
  <div :id="'works-' + mod + '-container'">
    <div class="horiz-scroll" :style="{'overflow-x': isMobile ? '' : 'hidden'}">
      <div class="scroll-works" :style="{ 'width': (data.horizWidth + 45) + 'px', 'height': (maxThumbHeight + 50) + 'px' }">
        <div v-for="(item, i) in data.items" :key="i" class="bg-28 round8" :style="{ width: maxThumbWidth + 'px', height: (maxThumbHeight + 40) + 'px', margin: '0 5px'}">
          <div :style="{ width: maxThumbWidth + 'px', height: maxThumbHeight + 'px', 'padding-left': item._raw_width < maxThumbWidth ? (maxThumbWidth - item._raw_width) / 2 + 'px' : '', 'padding-top': item._raw_height < maxThumbHeight ? (maxThumbHeight - item._raw_height) / 2 + 'px' : ''}">
            <img :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + item.workid + '/' + item.name + '.png?ver=' + item.update_at" :style="{ width: item._raw_width + 'px', height: item._raw_height + 'px'}" :class="{'round-top': maxThumbHeight - 16 < item._raw_height}" @click="openViewer(0, item.userid, item.workid)">
          </div>
          <div class="ellipsis align-center size12" :style="{ width: maxThumbWidth + 'px', height: '32px', padding: '8px 8px 0 8px' }">
            {{ item.name }}
          </div>
        </div>
        <!-- <div v-if="data.items.length > 2 && isMobile" class="more float-right" :style="{ 'height': (maxThumbHeight + 40) + 'px', 'line-height': (maxThumbHeight + 40) + 'px' }" @click="emit('gotoWorks', [params.classid === 1 ? 1 : 0])">
          <i class="iconfont my-right color-cc" />
        </div> -->
        <div v-if="data.items.length > 2 && isMobile" class="more float-right" :style="{ 'height': (maxThumbHeight + 40) + 'px', 'line-height': (maxThumbHeight + 40) + 'px' }" @click="emit('goto', ['works', { type: 'best' }])">
          <i class="iconfont my-right color-cc" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/works'
export default {
  mixins: [mixins]
}
</script>
