<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'nft-' + mod + '-container'" :style="listStyle" class="my-list my-nft-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px', 'height': simple ? '' : (item._height + 30) + 'px' }">
              <div>
                <div v-if="(mod ==='publish' || mod ==='preorder')" class="head" :class="{'color-66': item.sell_state === -1, 'color-ff': item.sell_tag[2]}" :style="{'color': item.sell_tag[1] || ''}">
                  <i v-if="item.sell_state === 0 && item.sell_tag[2]" class="iconfont my-remain size16" />
                  <i v-if="item.sell_state === 1" class="iconfont my-fire size16 red" />
                  <count-down v-if="item.sell_tag[2]" :pre-text="item.sell_tag[3] || ''" :current-time="item.now" :start-time="item.sell_tag[2]" :end-time="item.sell_tag[2]" @start_callback="countDownStartCb()" @end_callback="() => {}" />
                  <span v-if="!item.sell_tag[2] && item.sell_tag[0]"> {{ item.sell_tag[0] }}</span>
                </div>
                <div v-if="mod ==='paradrop'" class="head" :class="{'color-66': item.paradrop_state === -1, 'color-ff': item.paradrop_state === 0}" :style="{'color': item.paradrop_tag[1] || ''}">
                  <i class="iconfont my-airplane size16" :class="{'green': item.paradrop_state === 1, 'color-66': item.paradrop_state === -1}" />
                  <count-down v-if="item.paradrop_tag[2]" :current-time="item.now" :start-time="item.paradrop_tag[2]" :end-time="item.paradrop_tag[2]" @start_callback="countDownStartCb()" @end_callback="() => {}" />
                  <span v-if="!item.paradrop_tag[2] && item.paradrop_tag[0]"> {{ item.paradrop_tag[0] }}</span>
                </div>
                <div v-if="mod ==='compound'" class="head" :class="{'color-66': item.compound_state === -1, 'color-ff': item.compound_state === 0}" :style="{'color': item.compound_tag[1] || ''}">
                  <i class="iconfont my-compound size16" :class="{'green': item.compound_state === 1, 'color-66': item.compound_state === -1}" />
                  <count-down v-if="item.compound_tag[2]" :current-time="item.now" :start-time="item.compound_tag[2]" :end-time="item.compound_tag[2]" @start_callback="countDownStartCb()" @end_callback="() => {}" />
                  <span v-if="!item.compound_tag[2] && item.compound_tag[0]"> {{ item.compound_tag[0] }}</span>
                </div>
                <div v-if="mod ==='calendar'" class="head color-ff"><i class="iconfont my-latest size16" /> {{ getDate(item.sell_at) }}</div>
                <div :style="{ 'width': cardWidth + 'px', 'height': cardWidth + 'px', 'padding-top': item._padding_top + 'px' }" @click="openWorkViewer(item.userid, item.workid, item.publishid)">
                  <img :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + item.workid + '/' + item.name + '.png?ver=' + item.update_at" :class="{'round-top': cardWidth - 16 < item._raw_width}" :style="{ width: item._raw_width + 'px', height: item._raw_height + 'px' }">
                </div>
                <div class="title" :class="{'margin-bottom4': simple}">
                  <div class="left size14" style="width: calc(100% - 80px);" @click="openWorkViewer(item.userid, item.workid, item.publishid)">
                    <span v-if="!item.check" class="tag bg-red white">封</span>
                    {{ item.name }}<span v-if="nfts[item.publishid]"> #{{ padding(nfts[item.publishid].sn, 4) }}</span>
                    <span v-if="!simple && item.seriesid && series[item.seriesid] && series[item.seriesid].name" class="tag bg-gray white margin-left8">{{ series[item.seriesid].name }}</span>
                    <!-- <span v-for="(tag, idx) in item.tags" :key="idx" class="tag bg-gray white margin-left8">{{ tag }}</span> -->
                    <span v-if="!simple" class="tag bg-blue white margin-left8">限量{{ item.total }}份</span>
                  </div>
                  <div v-if="!simple" class="right yellow size14" @click="openWorkViewer(item.userid, item.workid, item.publishid)">
                    ¥{{ formateMoney(item.amount) }}
                  </div>
                  <div v-if="simple" class="right">
                    <el-button class="tap-btn" @click="() => showActionSheet(item)">
                      <i class="iconfont my-dot-more-v" />
                    </el-button>
                  </div>
                </div>
                <div v-if="!simple" class="foot">
                  <div class="left" @click="emit('goto', ['user', { userid: item.userid } ])">
                    <span v-if="!users[item.userid].avatar" class="el-avatar el-avatar--circle noavatar small">
                      <i class="iconfont my-man" />
                    </span>
                    <el-avatar v-if="users[item.userid].avatar" :size="24" :src="getAvatar(item)" />
                  </div>
                  <div class="center ellipsis" style="width:calc(100% - 120px);" @click="emit('goto', ['user', { userid: item.userid } ])">
                    {{ (users[item.userid]) ? users[item.userid].nickname : view.anonymity }}
                  </div>
                  <div class="right">
                    <el-button v-if="mod === 'praise'" class="tap-btn" @click="() => praiseIt(item.workid)">
                      <i class="iconfont my-praise-fill" />
                    </el-button>
                    <el-button v-if="mod !== 'praise'" class="tap-btn" @click="() => favorIt(item.workid)">
                      <i class="iconfont" :class="{ 'my-star': state.favorState[item.workid], 'my-blank-star': !state.favorState[item.workid] }" />
                    </el-button>
                  </div>
                  <div class="right">
                    <span v-if="mod === 'praise'">{{ state.praiseState[item.workid] && state.praiseState[item.workid] > 0 ? formateNums(state.praiseState[item.workid]) : '' }}</span>
                    <span v-if="mod !== 'praise'">{{ state.favorCount[item.workid] && state.favorCount[item.workid] > 0 ? formateNums(state.favorCount[item.workid]) : '' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">已显示全部内容</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" description="这里空空的" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/nft'
export default {
  mixins: [mixins]
}
</script>
