<template>
  <div v-show="config.show" class="my-container index">
    <div v-if="!isDesktop && config.showHead" class="header my-header">
      <div class="left">
        &nbsp;
      </div>
      <div class="middle">
        <img src="@/assets/logo.png" title="Gridy.Art 百格画">
      </div>
      <div class="right" />
    </div>
    <pull-to :id="'index-container'" :ref="'index-container'" :style="style" :top-load-method="(loaded) => renewView(loaded)" :bottom-load-method="(loaded) => getData(loaded)" @infinite-scroll="getData()">
      <el-carousel ref="swipeAds" v-finger:swipe="onSwipeAd" :interval="8000" arrow="hover" class="bg-22" @change="calcSwipeAdBgStyle">
        <div :style="swipeAdBgStyle" />
        <el-carousel-item v-for="(swipeAd, idx) in swipeAds" :key="idx" height="300" :style="swipeAdStyle(swipeAd)">
          <div style="width:100%; height:100%;" @click="linkTo(swipeAd.link)">
            <div class="title ellipsis color-ee">{{ swipeAd.tag + ' | ' + swipeAd.title }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="!isDesktop" class="my-row bg-22 margin-top10 align-center">
        <el-row>
          <el-col :span="8"><el-button class="tap-btn iconfont my-gridsfy size24 box40" @click="goto('editer')" /><div class="margin-top8 size12">创作像素画</div></el-col>
          <el-col :span="8"><el-button class="tap-btn iconfont my-avatar size24 box40" @click="openPopPage({ 'type': 'toBrickfy' })" /><div class="margin-top8 size12">定制拼图</div></el-col>
          <el-col :span="8"><el-button class="tap-btn iconfont my-post-add size24 box40" @click="emit('openPost')" /><div class="margin-top8 size12">发布主题</div></el-col>
        </el-row>
      </div>
      <!-- <div v-if="!isDesktop" class="my-row bg-22 margin-top10 align-center">
        <el-row>
          <el-col :span="4"><el-button class="tap-btn iconfont my-pixel size24 box40" @click="goto('editer')" /><div class="margin-top8 size12">百格画</div></el-col>
          <el-col :span="4"><el-button class="tap-btn iconfont my-gridsfy size24 box40" @click="openPopPage({ 'type': 'toBrickfy' })" /><div class="margin-top8 size12">拼图</div></el-col>
          <el-col :span="4"><el-button class="tap-btn iconfont my-avatar size24 box40" @click="openPopPage({ 'type': 'toPixel' })" /><div class="margin-top8 size12">像素画</div></el-col>
          <el-col :span="4"><el-button class="tap-btn iconfont my-cartoon size24 box40" @click="openPopPage({ 'type': 'toCartoon' })" /><div class="margin-top8 size12">AI动漫</div></el-col>
          <el-col :span="4"><el-button class="tap-btn iconfont my-ai-paint size24 box40" @click="openPopPage({ 'type': 'paint' })" /><div class="margin-top8 size12">AI作画</div></el-col>
          <el-col :span="4"><el-button class="tap-btn iconfont my-post-add size24 box40" @click="emit('openPost')" /><div class="margin-top8 size12">发主题</div></el-col>
        </el-row>
      </div>
      <div class="my-row bg-22 margin-top10 padding-top12 round-top4">
        <el-row>
          <el-col :span="8"><b>拼图</b></el-col>
          <el-col :span="16" class="right">
            <el-button class="tap-btn box24" @click="emit('gotoWorks', [1])">
              <i class="iconfont my-right" />
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="my-content">
        <works-item
          v-show="config.show"
          ref="brickfy-index"
          mod="index"
          :view="view"
          :state="state"
          :params="{ classid: 1 }"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :page-size="6"
          @receive="handle"
        />
      </div>
      <div class="my-row bg-22 margin-top10 padding-top12 round-top4">
        <el-row>
          <el-col :span="8"><b>像素画</b></el-col>
          <el-col :span="16" class="right">
            <el-button class="tap-btn box24" @click="emit('gotoWorks', [0])">
              <i class="iconfont my-right" />
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="my-content">
        <works-item
          v-show="config.show"
          ref="works-index"
          mod="index"
          :view="view"
          :state="state"
          :params="{ classid: 0 }"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :page-size="6"
          @receive="handle"
        />
      </div> -->
      <div class="my-row bg-22 margin-top10 padding-top12 round-top4">
        <el-row>
          <el-col :span="8"><b>作品</b></el-col>
          <el-col :span="16" class="right">
            <el-button class="tap-btn box24" @click="goto('works', { type: 'best' })">
              <i class="iconfont my-right" />
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="my-content">
        <works-item
          v-show="config.show"
          ref="works-index"
          mod="index"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :page-size="6"
          @receive="handle"
        />
      </div>
      <div class="my-row bg-22 margin-top8">
        <el-row>
          <el-col :span="8"><b>专辑</b></el-col>
          <el-col :span="16" class="right">
            <el-button class="tap-btn box24" @click="goto('album', { type: 'best' })">
              <i class="iconfont my-right" />
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="my-content round-bottom4">
        <album-item
          v-show="config.show"
          ref="album-index"
          mod="index"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :page-size="6"
          @receive="handle"
        />
      </div>
      <!-- <div class="my-row bg-22 round4 margin-top8">
        <el-row>
          <el-col :span="8" class="padding-top4"><b>AI作画</b></el-col>
          <el-col :span="16" class="right">
            <el-button class="tap-btn box24" @click="goto('aiart')">
              <i class="iconfont my-right" />
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="my-content">
        <aiart-item
          v-show="config.show"
          ref="aiart-index"
          mod="index"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :page-size="6"
          @receive="handle"
        />
      </div>
      <div v-if="showIdx > 0 || scrollTop > 50" class="my-row bg-22 margin-top10 round4">
        <el-row>
          <el-col :span="8" class="padding-top4"><b>数藏</b></el-col>
          <el-col :span="16" class="right">
            <el-button class="tap-btn box24" @click="goto('nft', { type: 'publish' })">
              <i class="iconfont my-right" />
            </el-button>
          </el-col>
        </el-row>
      </div>
      <nft-item
        v-if="showIdx > 0 || scrollTop > 50"
        v-show="config.show"
        ref="nft-index"
        mod="index"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :page-size="isMobile ? 1: 4"
        @receive="handle"
      /> -->
      <div v-if="showIdx > 1 || scrollTop > 150" class="active-users round4">
        <div class="my-row bg-22 round4">
          <el-row>
            <el-col :span="8"><b>创作者</b></el-col>
            <el-col :span="16" class="right">
              <el-button class="tap-btn box24" @click="goto('users', { type: 'new' })">
                <i class="iconfont my-right" />
              </el-button>
            </el-col>
          </el-row>
        </div>
        <creator-item
          v-if="showIdx > 1 || scrollTop > 150"
          v-show="config.show"
          ref="users-index"
          mod="index"
          :view="view"
          :state="state"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :page-size="isMobile ? 5: 8"
          @receive="handle"
        />
      </div>
      <div class="my-row bg-22 margin-top10 round4">
        <el-row>
          <el-col :span="8" class="padding-top4"><b>圈子</b></el-col>
          <el-col :span="16" class="right">
            <el-button class="tap-btn box24" @click="goto('zone', { type: 'new' })">
              <i class="iconfont my-right" />
            </el-button>
          </el-col>
        </el-row>
      </div>
      <thread-item
        v-show="config.show"
        ref="thread-index"
        mod="index"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :max-page="3"
        @receive="handle"
      />
    </pull-to>
    <div v-if="scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import PullTo from 'vue-pull-to'
// import aiartItem from '@/components/web/aiartItem'
// import nftItem from '@/components/web/nftItem'
import worksItem from '@/components/web/worksItem'
import threadItem from '@/components/web/threadItem'
import creatorItem from '@/components/web/creatorItem'
import albumItem from '@/components/web/albumItem'
import { mixins } from '@/mixins/common'
import service from '@/js/service'
import utils from '@/js/utils'
export default {
  components: {
    PullTo,
    // aiartItem,
    // nftItem,
    worksItem,
    threadItem,
    creatorItem,
    albumItem
  },
  mixins: [mixins],
  data() {
    const data = {}
    data.swipeAds = []
    data.swipeAdBgStyle = {}
    data.scrollTop = 0
    data.showIdx = 0
    return data
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    style() {
      if (this.isDesktop) {
        return { 'width': '100%', 'height': 'calc(100% - ' + (this.config.showHead ? 50 : 10) + 'px)' }
      }
      return { 'width': '100%', 'height': 'calc(100% - ' + (this.config.showHead ? 90 : 50) + 'px)' }
    },
    config() {
      return this.view.index
    }
  },
  watch: {
    'view.index.show': {
      handler() {
        if (this.view.index.show && !this.swipeAds.length) this.getSwipeAd()
      }
    }
  },
  mounted() {
    this.initScroll()
  },
  methods: {
    initScroll() {
      const container = this.scrollContainer()
      if (container) {
        container.onscroll = () => { this.scrollTop = container.scrollTop }
      } else {
        setTimeout(this.initScroll, 100)
      }
    },
    scrollContainer() {
      const main = document.getElementById('index-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    calcSwipeAdBgStyle(i) {
      const item = this.swipeAds[i]
      if (!item) {
        this.swipeAdBgStyle = {}
        return
      }
      const bg = this.getSwipeAdBackground(item)
      const style = {}
      if (bg) {
        style['position'] = 'absolute'
        style['width'] = '100%'
        style['height'] = '300px'
        style['background-image'] = 'url(' + bg + ')'
        style['background-position'] = 'center center'
        style['opacity'] = '0.3'
        style['filter'] = 'blur(3px)'
        style['-webkit-mask'] = '-webkit-linear-gradient(#ffffff, transparent)'
      }
      this.swipeAdBgStyle = style
    },
    swipeAdStyle(item) {
      const style = {}
      const bg = this.getSwipeAdBackground(item)
      if (bg) {
        style['background-image'] = 'url(' + bg + ')'
        style['background-position'] = 'center center'
        style['background-size'] = 'auto 100%'
        style['background-repeat'] = 'no-repeat'
      }
      return style
    },
    getSwipeAdBackground(item) {
      const ver = item.swipeid + '_' + item.update_at
      if (item.image) return service.getCosUrl(item.image, 'swipe_ad', ver) || ''
    },
    renewView(loaded) {
      this.callSub('brickfy-index', 'renewView')
      this.callSub('works-index', 'renewView')
      this.callSub('users-index', 'renewView')
      this.callSub('thread-index', 'renewView')
      this.callSub('album-index', 'renewView')
      this.getSwipeAd(loaded)
      this.backTop()
    },
    callSub(ref, fn) {
      const el = this.$refs[ref]
      if (el && el[fn]) {
        el[fn]()
      } else {
        setTimeout(() => {
          this.callSub(ref, fn)
        }, 100)
      }
    },
    handle(act, data) {
      if (act === 'setLockSwipe') {
        // console.log('TODO....setLockSwipe')
      } else {
        this.emit(act, data)
      }
    },
    getData(loaded) {
      this.showIdx++
      this.callSub('thread-index', 'getData')
      if (loaded) loaded('done')
    },
    getSwipeAd(loaded) {
      service.listGet('swipe', { sort_field: 'ordernum', sort: 'desc', simple: 1, limit: 5 }, (dt, type) => {
        if (type === 'success') {
          this.swipeAds = dt.data.items
          this.calcSwipeAdBgStyle(0)
          this.update()
        }
        loaded && loaded('done')
      })
    },
    linkTo(link) {
      if (utils.isUrl(link)) {
        window.open(link)
      } else {
        link = utils.jsonParse(link)
        if (link.mod) this.emit('goto', [link.mod, link.dt || {}])
      }
    },
    onSwipeAd(e) {
      if (e.direction === 'Left') {
        this.$refs['swipeAds'].prev()
      } else if (e.direction === 'Right') {
        this.$refs['swipeAds'].next()
      }
    }
  }
}
</script>
