/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-2-20
 * @copyright Gridy.Art
 */
export default {
  view: {
    loading: false,
    // 当前模块，editer: 编辑模式，works: 作品
    mod: 'works',
    mods: ['index', 'nft', 'works', 'aiart', 'album', 'albumWorks', 'zone', 'discover', 'topics', 'topicDetail', 'thread', 'reply', 'user', 'editer', 'setup', 'services', 'message', 'chat', 'wallet', 'task', 'orderMng', 'resource', 'resourceMng', 'resourceSelecter', 'cropImage', 'gridyViewer', 'workViewer', 'post', 'order', 'nftorder', 'invite', 'vip', 'sign', 'items', 'praise', 'favor', 'download', 'footprint', 'users', 'relation', 'profile', 'account'],
    resourceNames: { 'order': '订单', 'paint': 'AI作画', 'album': '专辑', 'bankcard': '银行卡', 'address': '地址', 'attachments_1': '素材', 'attachments_0': '文件', 'user_follow': '要@的人', 'work': '我的作品', 'nft': '数字藏品', 'topics': '话题' },
    resourceActs: { 'create': '创建', 'add': '添加', 'edit': '编辑', 'view': '查看' },
    showFootBarMods: ['index', 'works', 'aiart', 'album', 'zone', 'discover'],
    latestMod: '',
    historyMods: [],
    login: {
      from: '',
      show: false,
      loging: false,
      // 登录模式： username 用户名密码登录，phone 手机号登录，wechat 微信登录
      mod: 'phone',
      checked: false,
      username: '',
      password: '',
      phone: '',
      code: ''
    },
    addNew: {
      show: false
    },
    index: {
      show: false,
      showHead: false,
      loading: true,
      renew: ''
    },
    diy: {
      show: false,
      showHead: false,
      loading: true,
      renew: ''
    },
    nft: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      type: 'publish',
      typeid: 0,
      types: [['publish', '发售'], ['paradrop', '空投'], ['compound', '铸造'], ['calendar', '发售日历']]
      // types: [['publish', '发售'], ['preorder', '申购'], ['paradrop', '空投'], ['compound', '铸造'], ['calendar', '发售日历']]
    },
    // 未启用
    seriesWorks: {
      from: '',
      show: false,
      showHead: true,
      pop: true,
      loading: true,
      renew: '',
      seriesid: 0
    },
    works: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      classid: 0,
      type: 'new',
      typeid: 0,
      // types: [['new', '最新'], ['hot', '热门'], ['best', '推荐'], ['original', '原创']],
      types: [['new', '最新'], ['best', '推荐'], ['original', '原创']],
      catalogName: '全部',
      catalogid: 0,
      sort: 'desc',
      sortType: 'update',
      sortTypes: [['update', '更新时间'], ['create', '发布时间']]
    },
    aiart: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      type: 'new',
      typeid: 0,
      types: [['new', '最新'], ['best', '推荐']],
      catalogName: '全部',
      catalogid: 0,
      styleName: '全部',
      styleid: '0',
      origin: '',
      originTypes: [['', '全部'], ['0', '文生图'], ['1', '图生图']],
      sort: 'desc',
      sortType: 'update',
      sortTypes: [['update', '更新时间'], ['create', '发布时间']]
    },
    album: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      type: 'new',
      typeid: 0,
      // types: [['new', '最新'], ['hot', '热门'], ['best', '推荐'], ['original', '原创']],
      types: [['new', '最新'], ['best', '推荐'], ['original', '原创']],
      catalogName: '全部',
      catalogid: 0,
      sort: 'desc',
      sortType: 'update',
      sortTypes: [['update', '更新时间'], ['create', '创建时间']]
    },
    albumWorks: {
      from: 'album',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      typeid: 0,
      albumid: 0
    },
    zone: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      type: 'new',
      typeid: 0,
      types: [['new', '最新'], ['best', '推荐']],
      catalogName: '全部',
      catalogid: 0,
      sort: 'desc',
      sortType: 'update',
      sortTypes: [['update', '更新时间'], ['create', '发布时间']]
    },
    discover: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: ''
    },
    thread: {
      from: 'zone',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      threadid: 0
    },
    user: {
      from: '',
      show: false,
      showHead: false,
      hideBack: false,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      typeid: 0,
      types: [['thread', '主题'], ['works', '作品'], ['album', '专辑'], ['nft', '藏品']],
      userid: 0,
      // yes: 管理模式
      mng: 'no',
      resource: ''
    },
    topics: {
      from: 'zone',
      show: false,
      showHead: true,
      hideBack: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'hot',
      typeid: 0,
      types: [['new', '最新话题'], ['hot', '热门话题'], ['best', '推荐话题']]
    },
    topicDetail: {
      from: 'zone',
      show: false,
      showHead: true,
      hideBack: true,
      loading: true,
      pop: true,
      renew: '',
      topicid: 0
    },
    users: {
      from: 'discover',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'new',
      typeid: 0,
      types: [['new', '最新用户'], ['active', '活跃用户'], ['creator', '创作者']]
    },
    task: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: '',
      init: '',
      type: 'mine',
      typeid: 0,
      types: [['mine', '我的任务'], ['new', '可接任务'], ['finish', '已完成']]
    },
    relation: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'fans',
      typeid: 1,
      types: [['follow', '关注'], ['fans', '粉丝'], ['guest', '访客']],
      userid: 0
    },
    setup: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: ''
    },
    services: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: ''
    },
    message: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: '',
      type: 'dialog',
      types: [['dialog', '消息'], ['social', '互动消息'], ['system', '服务通知']]
    },
    chat: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      dialogId: 0,
      userid: 0,
      nickname: ''
    },
    wallet: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'flow',
      types: [['flow', '收支记录'], ['cash', '提现记录']]
    },
    items: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: '',
      type: 'all',
      typeid: 0,
      types: [['all', '全部'], ['bag', '背包'], ['store', '仓库']]
      // types: [['all', '全部'], ['bag', '背包'], ['store', '仓库'], ['hall', '展厅']]
    },
    order: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'all',
      typeid: 0,
      types: [['all', '全部'], ['paying', '待支付'], ['paid', '待发货'], ['receive', '待收货']]
      // types: [['all', '全部'], ['paying', '待支付'], ['paid', '待发货'], ['receive', '待收货'], ['comment', '待评价']]
    },
    nftorder: {
      from: 'nft',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'all',
      typeid: 0,
      types: [['all', '全部'], ['paying', '待付款'], ['paid', '已付款'], ['cancel', '已取消']]
    },
    orderMng: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      table: '',
      keyfield: '',
      id: '',
      act: 'add',
      // skuId 10002=钻石拼图 10001=积木拼图
      skuId: '',
      data: {}
    },
    resource: {
      from: '',
      show: false,
      showHead: false,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      table: '',
      showActions: true,
      simple: false,
      keyfield: '',
      type: 'all',
      tab: '',
      tabid: 0,
      tabs: [],
      data: {}
    },
    resourceMng: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      id: '',
      act: 'add',
      data: {}
    },
    resourceSelecter: {
      from: '',
      show: false,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      table: '',
      showActions: true,
      batchDelete: false,
      simple: false,
      keyfield: '',
      maxSelectCount: 1,
      type: 'selecter',
      typeid: 0,
      types: [],
      tab: '',
      tabid: 0,
      tabs: [],
      sift: 0,
      sifts: [],
      fn: null
    },
    cropImage: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    gridyViewer: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    paperViewer: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      hideSaveBtn: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    brickyViewer: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    workViewer: {
      from: 'works',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      workid: '',
      data: {},
      fn: null
    },
    post: {
      from: 'zone',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      id: '',
      act: 'add',
      catalogid: 0,
      catalogName: '',
      data: {}
    },
    invite: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: ''
    },
    vip: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: ''
    },
    sign: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: ''
    },
    praise: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      types: [['thread', '主题'], ['works', '作品'], ['album', '专辑']]
    },
    favor: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      types: [['thread', '主题'], ['works', '作品'], ['album', '专辑']]
    },
    download: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'works'
    },
    footprint: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      types: [['thread', '主题'], ['works', '作品'], ['album', '专辑']]
    },
    profile: {
      from: 'setup',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: ''
    },
    account: {
      from: 'setup',
      show: false,
      showHead: false,
      pop: true,
      renew: '',
      loading: true
    },
    itemOperate: {
      title: 'items',
      show: false,
      loading: false,
      type: ''
    },
    pop: {
      show: false,
      title: '',
      placeholder: '',
      classname: '',
      content: '',
      maxlength: 256,
      rows: 1,
      data: {},
      type: '',
      loading: false,
      fn: null
    },
    popPage: {
      from: '',
      // fromUrl: true,
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      title: '',
      mobile: '',
      publishid: 0,
      orderid: 0,
      // skuId 10002=钻石拼图 10001=积木拼图
      skuId: '',
      data: {},
      type: '',
      types: [['toCartoon', 'AI动漫'], ['toBrickArt', '百变拼图'], ['toPixel', 'AI像素画/像素头像'], ['toBrickfy', '定制拼图'], ['paint', 'AI作画'], ['order', '实物订单详情'], ['nftorder', '数字订单详情'], ['chainAccount', '区块链账户'], ['publishInfo', '藏品介绍'], ['publishPower', '藏品权益'], ['publishCert', '藏品证书'], ['nftnotice', '藏品提示'], ['bindPhone', '绑定新手机'], ['unbindPhone', '验证旧手机'], ['setPassword', '修改登录密码'], ['setPayPassword', '修改交易密码'], ['realVerify', '实名认证'], ['realVerifyInfo', '实名认证'], ['aboutUs', '关于我们'], ['feedback', '用户反馈'], ['checkUpdate', '检查更新'], ['agreement', '用户协议'], ['privacy', '隐私政策']]
    },
    myPop: {
      show: false,
      tab: 'draft',
      group: 'draft',
      mod: 'tab'
    },
    editer: {
      from: '',
      mod: '',
      workid: 0
    },
    albumSelector: {
      show: false,
      workid: 0
    },
    comment: {
      show: false,
      pop: true,
      work: {}
    },
    reply: {
      from: '',
      show: false,
      pop: true,
      work: {},
      threadid: 0,
      postid: 0
    },
    moreWorks: {
      show: false,
      title: ''
    },
    myPublish: {
      show: false,
      fn: null
    },
    actionSheet: {
      show: false,
      title: '',
      btns: []
    },
    myNotify: {
      show: false,
      title: '',
      content: '',
      btn: '',
      fn: null
    },
    sift: {
      show: false,
      type: 'works'
    },
    imagesViewer: {
      show: false,
      pop: true,
      list: []
    },
    anonymity: '用户',
    // del assets
    assets: false,
    zoomBar: false,
    rightSide: true,
    headerHeight: 50,
    leftSideWidth: 100,
    rightSideWidth: 288,
    rightToggleWidth: 12,
    rightToolBar: false,
    previewMod: '2d',
    createNewfile: false,
    scenes: true,
    objs: true,
    palette: true,
    inColors: true,
    outColors: true
  }
}
